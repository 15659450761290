@use "@styles/variables" as _variables;

$image-border-width: 0.5rem;

.arrow {
    width: 3rem;
    height: 2rem;
    border-radius: 2rem;
    background-image: url("~@images/carousel-chevron.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.container {
    width: 100%;

    > div {
        border: $image-border-width solid _variables.$color-white;
        background-color: _variables.$color-white;
        text-align: center;

        img {
            width: 100%;
        }
    }

    .nextBtn {
        @extend .arrow;
        transform: rotate(180deg);
    }

    .prevBtn {
        @extend .arrow;
    }

    .imageContainer {
        position: relative;
        border: $image-border-width solid _variables.$color-white;

        p {
            display: flex;
            justify-content: center;
            text-align: center;
            padding-bottom: 0.5rem;
            letter-spacing: 0.1rem;
            line-height: 1.7rem;
        }

        .name {
            max-width: 11rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        .imageContainer {
            p {
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-m) {
    .arrow {
        width: 2rem;
        height: 1rem;
        border-radius: 1rem;
        background-size: 0.5rem;
    }
}
