.item {
    width: 14rem;
    display: flex;

    img {
        width: 3rem;
        height: 3rem;
    }

    p {
        width: 100%;
        text-align: left;
        padding-left: 1rem;
        line-height: 3rem;
    }
}
