@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    @include _mixins.flex_container(row, center, center);

    .imageContainer {
        @include _mixins.flex_container(row, center, center);
        width: 45rem;
        height: 45rem;
        padding: 2rem;
        background-color: _variables.$color-white;
        z-index: 1;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .leftContainer,
    .rightContainer {
        @include _mixins.flex_container(column, flex-start, flex-start);
        width: 18rem;
        height: 45rem;
    }

    .avatarIdDesktop {
        padding: 2rem 0 1rem 0;
        font-size: _variables.$size-title-medium;

        span {
            color: _variables.$color-brown;
        }
    }

    .avatarIdMobile {
        @extend .avatarIdDesktop;
        display: none;
    }

    .traitContainer {
        width: 100%;

        .trait {
            padding: 0.2rem 0 0.5rem 0;

            &:not(:last-child) {
                border-bottom: 0.1rem solid _variables.$color-light-gray;
            }

            p {
                @include _mixins.flex_container(row, space-between, center);
                width: 100%;
                text-transform: uppercase;

                span {
                    font-size: _variables.$size-paragraph-medium-trait;
                }
            }
        }
    }

    .btnContainer {
        margin-top: auto;
        margin-bottom: _variables.$background-bottom-banner;

        a {
            @include _mixins.btn_primary(_variables.$color-brown, _variables.$color-white);
            background-color: _variables.$color-black;
            border: _variables.$color-black;
            width: 7rem;
            height: 7rem;
            text-decoration: none;
            text-align: center;
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-ml) {
    .container {
        @include _mixins.flex_container(column, center, center);

        .avatarIdDesktop {
            display: none;
        }

        .avatarIdMobile {
            display: block;
            font-size: _variables.$size-paragraph-big;
            margin-bottom: 1rem;
            justify-self: start;
            width: 100%;

            span {
                font-size: _variables.$size-paragraph-big;
            }
        }

        .imageContainer {
            padding: 0;
            height: 25rem;

            > img {
                width: 100%;
                height: 100%;
            }
        }

        .leftContainer {
            height: 0;
        }

        .imageContainer,
        .traitContainer,
        .rightContainer {
            @include _mixins.flex_container(column, center, center);
            width: 25rem;
        }

        .traitContainer .trait {
            width: 100%;
        }

        .btnContainer {
            width: 100%;
            margin-top: 1.5rem;
            margin-bottom: 3rem;

            a {
                margin: auto;
            }
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        padding: 0 2rem;

        .avatarIdDesktop {
            display: none;
        }

        .avatarIdMobile {
            display: block;
            font-size: _variables.$size-paragraph-big;
            margin-bottom: 1rem;
            justify-self: start;
            width: 100%;

            span {
                font-size: _variables.$size-paragraph-big;
            }
        }

        .imageContainer {
            width: 100%;
            height: 100%;
            padding: 0;
            margin-bottom: 2rem;
        }

        .leftContainer {
            height: 0;
        }

        .imageContainer,
        .traitContainer,
        .rightContainer {
            @include _mixins.flex_container(column, center, center);
            max-width: 25rem;
            width: 100%;
            height: 100%;
        }

        .traitContainer .trait {
            p {
                span {
                    font-size: _variables.$mobile-paragraph-trait;
                }
            }
        }

        .btnContainer {
            width: 100%;
            margin-top: 1.5rem;
            margin-bottom: 3rem;

            a {
                margin: auto;
            }
        }
    }
}
