@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    @include _mixins.flex_container(column, center, center);
    width: 100%;
}

.traitBox,
.rarityBox {
    width: 100%;
    color: _variables.$color-white;
    border: none;
}

.traitBox {
    margin-bottom: 3rem;
}

.item {
    @include _mixins.flex_container(row, flex-start, baseline);

    input {
        margin: 0 1rem 1.5rem 0;

        &::before {
            content: "";
            width: 1rem;
            height: 1rem;
            background-color: _variables.$color-white;
            position: absolute;
            cursor: pointer;
        }

        &:checked::before {
            background-color: _variables.$color-black;
        }
    }
}

@media screen and (max-width: #{_variables.$media-laptop-lm}) {
    .item {
        font-size: _variables.$size-paragraph-small;
    }

    .traitBox {
        margin-bottom: 1rem;
    }
}
