@use "@styles/variables" as _variables;

.container {
    background: _variables.$color-brown;
    height: _variables.$background-bottom-banner;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
}
