@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    width: 100%;
    margin: auto;
    position: relative;
    margin-top: 7rem;
}

a {
    text-decoration: none;
    color: _variables.$color-brown;
}

.content {
    position: relative;
    display: grid;
    grid-template-areas:
        "tab   tab   tab   preview preview"
        "table table table preview preview";
    grid-template-rows: 4.5rem 1fr;

    max-width: _variables.$inner-content-max-width;
    margin: auto;
    padding: 1.5rem;
    z-index: 1;
    background-color: _variables.$color-white;
}

.title,
.titleBrown {
    width: 100%;
    max-width: _variables.$inner-content-max-width;
    text-align: center;
    color: _variables.$color-black;
    font-size: _variables.$size-paragraph-big;
    margin: -2rem auto 2rem auto;

    span {
        font-size: _variables.$size-paragraph-big;
        color: _variables.$color-brown;
    }
}

.titleLast {
    margin-bottom: 1rem;
}

.changes {
    font-size: _variables.$size-paragraph-small;
    text-align: center;
    margin-bottom: 2rem;
}
