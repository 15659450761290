@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    @include _mixins.flex_container(row, center, flex-end);
    width: 100%;
    height: auto;

    img {
        display: block;
    }

    section {
        @include _mixins.flex_container(column, flex-start, center);
        width: 100%;
        position: relative;

        .onlyMobile {
            display: none;
        }

        .onlyDesktop {
            display: block;
        }

        .floatingLogo {
            position: fixed;
            max-width: 7.8rem;
            right: 7rem;
            top: _variables.$height-header;
            z-index: 999;
        }

        .slider {
            position: relative;
            width: 100%;
            height: 50vw;
            background-color: _variables.$color-black;
            z-index: 1;
        }

        .prhaseContainer {
            width: 100%;
            position: relative;

            div {
                width: calc(#{_variables.$inner-content-max-width} - #{_variables.$inner-content-correction});
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                .first {
                    width: 100%;
                    padding: 0;
                    font-size: 9.5rem;
                    line-height: 85%;
                    font-family: _variables.$font-bebas-neues;
                    color: _variables.$color-white;
                    text-align-last: justify;
                    white-space: nowrap;
                    display: inline-block;
                }

                .second {
                    @extend .first;
                    color: _variables.$color-brown;
                }
                .third {
                    @extend .first;
                    font-size: 8rem;
                }
            }

            picture,
            img {
                max-width: 100%;
            }
        }

        > p {
            max-width: calc(#{_variables.$inner-content-max-width} - #{_variables.$inner-content-correction});
            margin: _variables.$top-bottom-space 2rem;
            text-align: justify;

            > span {
                color: _variables.$color-brown;
            }
        }

        picture,
        picture img {
            width: 100%;
        }

        .avatars {
            width: _variables.$inner-content-max-width;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 1rem;
            row-gap: 1rem;
            z-index: 1;
            background-color: _variables.$color-white;
            padding: 0 1.2rem 1.2rem 1.2rem;

            div {
                a,
                img {
                    width: 100%;
                    height: 100%;
                }

                button {
                    width: 100%;
                    height: 100%;
                    @include _mixins.btn_primary(_variables.$color-brown, _variables.$color-white);
                }

                a {
                    @include _mixins.btn_primary(_variables.$color-brown, _variables.$color-white);
                }

                .seemore {
                    width: 100%;
                    height: 100%;
                    @include _mixins.btn_primary(_variables.$color-black, _variables.$color-white);
                }
            }
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        section {
            .onlyMobile {
                display: block;
            }

            .onlyDesktop {
                display: none;
            }

            .floatingLogo {
                display: none;
            }

            .prhaseContainer {
                div {
                    width: 58%;

                    .first {
                        font-size: 10vw;
                    }

                    .second {
                        font-size: 10vw;
                    }

                    .third {
                        font-size: 8.5vw;
                    }
                }
            }

            > p {
                max-width: 100%;
                padding: 0 2rem;
                margin: 2rem 0;
                font-size: _variables.$mobile-paragraph-medium;
                text-align: center;

                span {
                    font-size: _variables.$mobile-paragraph-medium;
                }
            }

            .avatars {
                max-width: calc(100% - 2rem);
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-l) {
    .container {
        section {
            .slider {
                height: 70vw;
            }

            .prhaseContainer {
                div {
                    width: 56%;

                    .first {
                        font-size: 9.5vw;
                    }

                    .second {
                        font-size: 9.5vw;
                    }

                    .third {
                        font-size: 8vw;
                    }
                }
            }

            > p {
                padding: 0 4rem;
            }

            .avatars {
                max-width: calc(100% - 5.5rem);
                grid-gap: 0.6rem;
                row-gap: 0.6rem;
                padding: 0 0.8rem 0.8rem 0.8rem;

                div {
                    a,
                    .seemore,
                    button {
                        line-height: 0.9rem;
                        font-size: _variables.$mobile-paragraph-small;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-m) {
    .container {
        section {
            .slider {
                height: 70vw;
            }

            .prhaseContainer {
                div {
                    width: 57%;

                    .first {
                        font-size: 9vw;
                    }

                    .second {
                        font-size: 8.8vw;
                    }

                    .third {
                        font-size: 7.5vw;
                    }
                }
            }

            > p {
                padding: 0 2rem;
            }

            .avatars {
                max-width: calc(100% - 2rem);
                grid-gap: 0.5rem;
                row-gap: 0.5rem;
                padding: 0 0.7rem 0.7rem 0.7rem;
            }
        }
    }
}

@media screen and (max-device-width: _variables.$media-mobile-s) {
    .container {
        section {
            .slider {
                height: 812px;
                margin-top: -5rem;
            }

            .prhaseContainer {
                display: none;
            }
        }
    }
}
