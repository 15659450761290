@use "./_variables" as _variables;
@use "./_placeholders";

/// Primary button factory.
///
/// @param {string} $background-color
///   Background color for buttons.
/// @return {string} $colo.
///   Font color
@mixin btn_primary($background-color, $color) {
    @extend %btn_base;
    color: $color;
    background-color: $background-color;
    border: 0.25rem solid $background-color;
}

/// Button link.
///
/// @param {string} $color
///   Fonr color and border color for buttons.
@mixin btn_link($color) {
    color: $color;
    background-color: transparent;
    border: none;
    font-size: _variables.$size-button-medium;
}

/// Flex container shortcut.
///
/// @param {string} $direction
///   flex-direction value
/// @param {string} $justify
///   justify-content value
/// @param {string} $align
///   align-items value
@mixin flex_container($direction, $justify, $align) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}
