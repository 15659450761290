@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    width: 100%;
    margin: 5rem auto;
    @include _mixins.flex_container(column, center, center);

    .discordLink {
        img {
            max-width: calc(#{_variables.$inner-content-max-width} - #{_variables.$inner-content-correction});
        }
    }

    .roadmap {
        @include _mixins.flex_container(row, center, unset);
        width: 100%;
        margin: auto;
        max-width: calc(#{_variables.$inner-content-max-width} - #{_variables.$inner-content-correction});

        span {
            color: _variables.$color-brown;
            line-height: 1.8rem;
            font-size: 1.3rem;
            letter-spacing: 0.081rem;
            text-align: center;
            margin-bottom: 2rem;
            font-weight: 300;
        }

        p {
            font-size: _variables.$size-paragraph-medium;
            letter-spacing: 0.125rem;
            line-height: 1.5;
            margin-bottom: 0.2rem;
        }

        .highlightedText {
            font-size: _variables.$size-paragraph-big;
            letter-spacing: 0.125rem;
            line-height: 1.5;
            color: _variables.$color-brown;
        }

        .highlightedTextNoMarginBottom {
            @extend .highlightedText;
            margin-bottom: 0;
        }

        .highlightedTextNormalSize {
            @extend .highlightedText;
            font-size: _variables.$size-paragraph-medium;
        }

        .importantText {
            font-size: _variables.$size-paragraph-big;
            margin-bottom: 1rem;
        }

        .importantTextNoMarginBottom {
            @extend .importantText;
            margin-bottom: 0;
        }

        .noMarginBottom {
            margin-bottom: 0 !important;
        }

        &Info {
            @include _mixins.flex_container(column, center, center);
            width: 70%;
            padding-left: 2rem;

            .intro {
                margin-bottom: 3rem;
                max-width: 24rem;
                align-self: baseline;
            }

            .quarters {
                width: 100%;

                p {
                    margin-bottom: 0.7rem;

                    &:last-child {
                        margin-bottom: 3rem;
                    }
                }

                .months {
                    color: _variables.$color-brown;
                }

                .outro {
                    margin-top: 1rem;
                }

                .pastSeasonTitle {
                    color: _variables.$color-brown-42;
                }
                .pastSeasonMonths {
                    color: _variables.$color-brown-42;
                }

                .pastEvents {
                    color: _variables.$color-light-gray;
                }
            }
        }

        &Logos {
            @include _mixins.flex_container(column, flex-start, center);
            width: 30%;
            padding-left: 5rem;

            .verticalLine {
                &:after {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    height: 111rem;
                    border-left: 0.125rem dotted _variables.$color-black;
                    margin-top: 19.8rem;
                }
            }

            .horizontalLine {
                @include _mixins.flex_container(row, center, center);

                span {
                    margin-bottom: 0;
                }

                .dotted {
                    white-space: nowrap;
                    position: relative;
                    overflow: hidden;

                    &:after {
                        content: "..........................";
                        letter-spacing: 0.05rem;
                        font-size: _variables.$size-paragraph-medium;
                        color: _variables.$color-black;
                        vertical-align: 0.5rem;
                        display: inline-block;
                        padding-left: 0.6rem;
                        margin-right: 10rem;
                    }
                }
            }

            img {
                width: 100%;
            }

            .qklogo {
                max-width: 10rem;
                margin-top: 11rem;
            }

            .img2300,
            .img4600 {
                max-width: 7rem;
            }

            .img2300 {
                margin-bottom: 1.5rem;
            }

            .firstHorizontalLine {
                @extend .horizontalLine;
                margin-top: -1.8rem;
            }

            .snowflake,
            .burning,
            .rainbow {
                padding: 0.5rem 0;
            }

            .snowflake,
            .sandbox,
            .rainbow {
                max-width: 4rem;
                background-color: _variables.$color-white;
            }

            .rainbow {
                margin-top: 27rem;
            }

            .snowflake {
                margin-top: 21.5rem;
            }

            .burning {
                max-width: 3rem;
                margin-top: 6.5rem;
                background-color: _variables.$color-white;
            }

            .secondHorizontalLine {
                @extend .horizontalLine;
                margin-top: 20.5rem;
            }

            .sandbox {
                margin-top: 1rem;
            }
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        margin-top: 3rem;
        padding: 0 2rem;

        .discordLink {
            img {
                max-width: 100%;
            }
        }

        .roadmap {
            span {
                color: _variables.$color-brown;
                line-height: 1.8rem;
                font-size: _variables.$size-paragraph-big;
                letter-spacing: 0.081rem;
                text-align: center;
                margin-bottom: 2rem;
                font-weight: 300;
            }

            p {
                font-size: _variables.$size-paragraph-small;
                line-height: 1.5rem;
            }

            .highlightedText {
                font-size: _variables.$size-paragraph-medium;
                letter-spacing: 0.125rem;
                line-height: 1.5;
                color: _variables.$color-brown;
            }

            .highlightedTextNoMarginBottom {
                margin-bottom: 0;
            }

            .highlightedTextNormalSize {
                font-size: _variables.$size-paragraph-small;
            }

            .importantText {
                font-size: _variables.$size-paragraph-medium;
                margin-bottom: 1rem;
            }

            .importantTextNoMarginBottom {
                margin-bottom: 0;
            }

            .noMarginBottom {
                margin-bottom: 0 !important;
            }

            &Info {
                padding-left: 2rem;
            }

            &Logos {
                padding-left: 5rem;
                min-width: 15rem;

                .verticalLine {
                    &:after {
                        height: 115rem;
                        margin-top: 18.5rem;
                    }
                }

                .horizontalLine {
                    span {
                        margin-bottom: 0;
                    }

                    .dotted {
                        white-space: nowrap;
                        position: relative;
                        overflow: hidden;

                        &:after {
                            content: "..........................";
                            letter-spacing: 0.05rem;
                            font-size: _variables.$size-paragraph-medium;
                            color: _variables.$color-black;
                            vertical-align: 0.5rem;
                            display: inline-block;
                            padding-left: 0.6rem;
                            margin-right: 10rem;
                        }
                    }
                }

                img {
                    width: 100%;
                }

                .qklogo {
                    max-width: 10rem;
                    margin-top: 9rem;
                }

                .img2300,
                .img4600 {
                    max-width: 7rem;
                }

                .img2300 {
                    margin-top: 0.5rem;
                }

                .firstHorizontalLine {
                    margin-top: -1.8rem;
                }

                .img4600 {
                    margin-top: 0.3rem;
                }

                .rainbow {
                    margin-top: 28rem;
                }

                .snowflake,
                .rainbow {
                    max-width: 4rem;
                }

                .snowflake {
                    margin-top: 23rem;
                }

                .burning {
                    max-width: 3rem;
                    margin-top: 6rem;
                }

                .secondHorizontalLine {
                    margin-top: 21.5rem;
                }

                .sandbox {
                    max-width: 3.5rem;
                    margin-top: 2rem;
                }
            }
        }
    }
}

@media screen and (max-width: 50rem) {
    .container {
        .roadmap {
            & .info {
                padding-left: 0rem;
            }
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-l) {
    .container {
        .roadmap {
            p {
                font-size: _variables.$mobile-paragraph-small;
                line-height: 1.5rem;
            }

            .highlightedText {
                font-size: _variables.$size-paragraph-small;
                letter-spacing: 0.125rem;
                line-height: 1.5;
                color: _variables.$color-brown;
            }

            .highlightedTextNormalSize {
                font-size: _variables.$mobile-paragraph-small;
            }

            .importantText {
                font-size: _variables.$size-paragraph-small;
                margin-bottom: 1rem;
            }

            &Info {
                padding-left: 0;
                width: 12rem;

                .quarters {
                    .freezingParagraph {
                        margin-top: 0rem;
                    }
                }
            }

            &Logos {
                padding-left: 0rem;
                min-width: 10rem;

                .verticalLine {
                    &:after {
                        height: 174rem;
                        margin-top: 23rem;
                    }
                }

                .horizontalLine {
                    .dotted {
                        &:after {
                            content: ".............";
                            margin-right: 6rem;
                        }
                    }
                }

                .qklogo {
                    max-width: 8rem;
                    margin-top: 16rem;
                }

                .img2300,
                .img4600 {
                    max-width: 5rem;
                }

                .img2300 {
                    margin-top: 4.2rem;
                }

                .firstHorizontalLine {
                    margin-top: 0;
                }

                .img4600 {
                    margin-top: 1rem;
                }

                .rainbow {
                    margin-top: 46rem;
                }

                .snowflake,
                .rainbow,
                .sandbox {
                    max-width: 3rem;
                }

                .snowflake {
                    margin-top: 38rem;
                }

                .burning {
                    max-width: 2.5rem;
                    margin-top: 10rem;
                }

                .secondHorizontalLine {
                    margin-top: 31.5rem;
                }

                .sandbox {
                    margin-top: 4rem;
                }
            }
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-m) {
    .container {
        padding: 1rem;

        .roadmap {
            &Info {
                padding-left: 0;
                width: 10rem;
            }

            &Logos {
                min-width: 10rem;

                .verticalLine {
                    &:after {
                        height: 196rem;
                        margin-top: 26rem;
                    }
                }

                .qklogo {
                    margin-top: 19rem;
                }

                .img2300 {
                    margin-top: 5.7rem;
                }

                .img4600 {
                    margin-top: 1rem;
                }

                .rainbow {
                    margin-top: 48rem;
                }

                .snowflake {
                    margin-top: 41rem;
                }

                .burning {
                    margin-top: 12rem;
                }

                .secondHorizontalLine {
                    margin-top: 39rem;
                }

                .sandbox {
                    margin-top: 4.5rem;
                }
            }
        }
    }
}
