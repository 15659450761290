@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    ul {
        list-style: none;
        @include _mixins.flex_container(row, flex-start, center);

        > .separator {
            color: _variables.$color-white;
        }

        > li {
            font-size: _variables.$size-font-footer;
            margin: 0 0.35rem;

            > a {
                text-decoration: none;
                color: _variables.$color-white;

                &:hover {
                    color: _variables.$color-brown;
                }
            }

            > .activeLink {
                color: _variables.$color-brown;
            }

            &:nth-child(1) {
                margin-left: 0;
            }
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        width: 100%;

        ul {
            @include _mixins.flex_container(column, flex-start, flex-start);

            .separator {
                display: none;
            }

            li {
                font-size: _variables.$mobile-font-footer;
                margin: 0.4rem 0;
            }
        }
    }
}
