@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    @include _mixins.flex_container(row, center, flex-end);
    width: 100%;
    height: auto;

    .loader {
        width: 100%;
        margin-bottom: 15rem;
    }

    .avatars {
        width: _variables.$inner-content-max-width;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
        row-gap: 1rem;
        z-index: 1;
        background-color: _variables.$color-white;
        padding: 0 1.2rem 1.2rem 1.2rem;

        .avatarContainer {
            max-width: 17.2rem;
            max-height: 17.2rem;
            width: 100%;
            height: 100%;
        }

        div {
            a,
            img {
                width: 100%;
                height: 100%;
            }

            button {
                width: 100%;
                height: 100%;
                @include _mixins.btn_primary(_variables.$color-brown, _variables.$color-white);
            }

            a {
                @include _mixins.btn_primary(_variables.$color-brown, _variables.$color-white);
            }

            .seemore {
                width: 100%;
                height: 100%;
                @include _mixins.btn_primary(_variables.$color-black, _variables.$color-white);
            }
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        .avatars {
            max-width: calc(100% - 2rem);
            width: 100%;
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-l) {
    .container {
        .avatars {
            max-width: calc(100% - 5.5rem);
            grid-gap: 0.6rem;
            row-gap: 0.6rem;
            padding: 0 0.8rem 0.8rem 0.8rem;

            .avatarContainer {
                height: auto;
            }

            div {
                a,
                .seemore,
                button {
                    line-height: 0.9rem;
                    font-size: _variables.$mobile-paragraph-small;
                }
            }
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-m) {
    .container {
        .avatars {
            max-width: calc(100% - 2rem);
            height: auto;
            grid-gap: 0.5rem;
            row-gap: 0.5rem;
            padding: 0 0.7rem 0.7rem 0.7rem;

            .avatarContainer {
                height: auto;
            }
        }
    }
}
