@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    width: 85%;

    .rarity {
        padding: 0.02rem 0;

        &:not(:last-child) {
            border-bottom: 0.1rem solid _variables.$color-light-gray;
        }
    }

    p {
        @include _mixins.flex_container(row, space-between, center);
        width: 100%;
        text-transform: uppercase;

        span {
            color: _variables.$color-black;
            font-size: _variables.$size-paragraph-small-trait;
        }
    }
}

@media screen and (max-width: #{_variables.$media-laptop-lm}) {
    .container {
        width: 93%;

        .rarity {
            padding: 0;
        }

        p {
            span {
                font-size: _variables.$size-paragraph-very-small;
                line-height: 1.4rem;
            }
        }
    }
}
