@use "@styles/_variables" as _variables;

.title,
.titleBrown {
    width: 100%;
    max-width: _variables.$inner-content-max-width;
    text-align: center;
    color: _variables.$color-white;
    font-size: _variables.$size-paragraph-big;
    margin: -2rem auto 2rem auto;

    span {
        font-size: _variables.$size-paragraph-big;
        color: _variables.$color-brown;
    }
}

.learnmore {
    text-align: center;
    margin-bottom: 1rem;
    margin-top: -1rem;
}
