@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    @include _mixins.flex_container(column, flex-start, center);
    position: fixed;
    background-color: _variables.$color-black;
    width: 100%;
    z-index: 999;

    > .imagesButtonContainer {
        width: 100%;
        height: 5rem;
        padding: 1rem 0 1rem 1.5rem;
        @include _mixins.flex_container(row, flex-start, center);

        > button {
            margin-right: 1.5rem;
            border: none;
            background: none;
            width: 2rem;
            height: 2rem;
        }

        > a {
            > img {
                width: 5rem;
            }
        }

        > .linkContainer {
            width: 100%;
            @include _mixins.flex_container(row, flex-end, center);

            > a,
            button {
                &:nth-child(1) {
                    @include _mixins.btn_primary(_variables.$color-brown, _variables.$color-white);
                }

                &:nth-child(2) {
                    @include _mixins.btn_primary(_variables.$color-solid-pink, _variables.$color-white);
                }

                &:nth-child(1),
                &:nth-child(2) {
                    width: 5rem;
                    height: 5rem;
                    font-size: 0.65rem;
                    line-height: 0.8rem;
                }
            }
        }
    }
}

@media screen and (min-width: #{_variables.$media-laptop-m + 1}) {
    .container {
        display: none;
    }
}
