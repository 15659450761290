@use "@styles/_variables" as _variables;

.mainCarousel {
    position: relative;
    height: 50vw;
    width: 100%;
    background-color: _variables.$color-black;
    z-index: 1;
}

@media screen and (max-width: 960px) {
    .mainCarousel {
        height: 185vw;
    }
}
