@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    @include _mixins.flex_container(column, center, flex-start);
    position: relative;

    a {
        .traitTaken {
            margin-top: 0;
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url("~@images/attached-trait-icon.png");
            background-size: contain;
        }

        img {
            max-width: 7.5rem;
            max-height: 7.5rem;
        }
    }

    div {
        margin-top: 0.5rem;

        p {
            font-size: 0.9rem;
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-l) {
    .container {
        a {
            img {
                max-width: 5rem;
                max-height: 5rem;
            }
        }

        div.stats {
            width: 100%;

            p {
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        margin: 0 0.65rem;
    }
}
