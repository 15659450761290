@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.switchParagraph {
    max-width: _variables.$inner-content-max-width;
    text-align: center;
    margin-bottom: 3rem;
    font-weight: normal;
}

.loader {
    margin: 2rem 0;
}

.technicalError {
    color: _variables.$color-brown;
}

.container {
    @include _mixins.flex_container(column, center, center);
    width: 100%;
    height: auto;
    padding-top: 5rem;

    a {
        color: _variables.$color-brown;
        text-decoration: none;
        cursor: pointer;
    }

    .loadingText {
        color: _variables.$color-black;
        margin-bottom: 1rem;
    }

    .mintContainer {
        @include _mixins.flex_container(row, space-around, center);
        width: _variables.$inner-content-max-width;
        height: auto;
        margin-bottom: 5rem;

        img,
        .btnPrimary {
            width: 18rem;
            height: 18rem;
        }

        .disabled {
            background-color: _variables.$color-medium-gray;
            border-color: _variables.$color-medium-gray;
            cursor: no-drop;
        }
    }

    .quantityContainer {
        padding-bottom: _variables.$bottom-space-footer;
    }

    .lowBalance {
        color: red;
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        width: 100%;
        padding: 2rem;

        > p {
            max-width: 100%;
            margin: 0 0 1.5rem 0;
            font-size: _variables.$mobile-paragraph-medium;

            span {
                font-size: _variables.$mobile-paragraph-medium;
            }
        }

        .allUsersParagraphTop,
        .allUsersParagraphBottom,
        .onlyConnectWalletAndNormalMessage {
            margin-bottom: 0;
        }

        .claimed {
            text-align: center;

            span {
                display: block;
            }
        }

        .mintContainer {
            width: 100%;
            margin-bottom: 2rem;

            img,
            .btnPrimary {
                width: 28vw;
                height: 28vw;
            }

            .btnPrimary {
                font-size: _variables.$mobile-paragraph-small;
            }
        }

        .quantityContainer {
            padding-bottom: 3rem;
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-l) {
    .technicalError {
        font-size: _variables.$mobile-paragraph-small;
    }

    .container {
        .mintContainer {
            .btnPrimary {
                line-height: 0.8rem;
            }
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-m) {
    .container {
        p,
        p span {
            font-size: _variables.$mobile-paragraph-small;
            line-height: 1.5rem;
        }

        .mintContainer {
            img,
            .btnPrimary {
                width: 26vw;
                height: 26vw;
            }
        }
    }
}
