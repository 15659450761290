@use "@styles/_variables.scss" as _variables;

.container {
    width: 10rem;
    height: 10rem;
    margin: auto;
    position: relative;
}

.circle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
        content: "";
        width: 50%;
        height: 50%;
        border-radius: 50%;
        border: 1rem solid _variables.$color-gray-loader;
        border-top: 1rem solid _variables.$color-black;
        -webkit-animation: spin 1s linear infinite; /* Safari */
        animation: spin 1s linear infinite;
    }
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.innerLoaderImage {
    position: absolute;
    height: 40%;
}
