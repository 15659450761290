@use "@styles/_variables" as _variables;
@use "@styles/_placeholders";

.placeHolder {
    .loader {
        width: 5rem;
        height: 5rem;
    }

    .circle {
        &::before {
            border: 0.5rem solid _variables.$color-gray-loader;
            border-top: 0.5rem solid _variables.$color-black;
        }
    }
}

.container {
    padding: 1rem 0;
    width: 32rem;
    height: 16.3rem;
    margin-top: -1rem;
    display: grid;
    grid-template-columns: repeat(5, 5rem);
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2.5rem;
    background-color: _variables.$color-background-simulator;
}

.traitContainer {
    &.cursorPointer {
        cursor: pointer;
    }

    &.cursorNone {
        pointer-events: none;
    }

    p {
        color: _variables.$color-white;
        text-transform: uppercase;
        font-size: _variables.$size-paragraph-very-small;
        letter-spacing: 0.03rem;
    }

    div {
        width: 5rem;
        height: 5rem;
        background-color: _variables.$color-black;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.selectedTraitOpensea {
    outline: 0.2rem solid _variables.$color-white;
    outline-offset: -0.1875rem;
}

@media screen and (max-width: #{_variables.$media-laptop-lm}) {
    .placeHolder {
        .loader {
            width: 4rem;
            height: 4rem;
        }
    }

    .container {
        width: 26rem;
        height: 14rem;
        margin-top: -1rem;
        gap: 1rem;
        margin-bottom: 2.5rem;
        grid-template-columns: repeat(5, 4rem);
    }

    .traitContainer {
        p {
            font-size: 0.5rem;
            margin-top: -0.5rem;
        }

        div {
            width: 4rem;
            height: 4rem;
        }
    }
}
