@use "@styles/_variables" as _variables;

.container {
    margin: 5rem auto 10rem auto;
    max-width: _variables.$inner-content-max-width;
    text-transform: uppercase;
    text-align: start;

    h1,
    h2 {
        color: _variables.$color-brown;
    }

    span {
        font-size: _variables.$size-title-medium;
    }

    .capitalizedParagraph {
        text-transform: capitalize;
    }

    p {
        margin-bottom: 1rem;
        text-align: justify;
    }

    a {
        color: _variables.$color-brown;
        font-weight: bold;
        text-decoration: underline;
    }

    h1 {
        margin-bottom: 1rem;
    }

    h2,
    a {
        margin-top: 1rem;
    }

    .dottedLine,
    a,
    h2 {
        font-size: _variables.$size-subtitle;
    }

    p > a {
        font-size: _variables.$size-paragraph-big;
    }

    .normalParagraph {
        font-weight: normal;
        font-size: _variables.$size-paragraph-big;
    }

    .boldParagraph,
    .boldParagraphNoMarginBottom {
        font-weight: bold;
    }

    .boldParagraphNoMarginBottom,
    .dottedLine {
        margin-bottom: 0;
    }

    .bulletList {
        padding-left: 2rem;
        margin-bottom: 1rem;

        li {
            margin-bottom: 1rem;
        }

        p {
            display: inline;
        }
    }

    .noBullet {
        list-style-type: none;
        margin-bottom: 0 !important;
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        margin-top: 3rem;
        padding: 0 2rem;

        h1,
        h1 span {
            font-size: 1.125rem;
        }

        h2 {
            margin-top: 1rem;
            font-size: _variables.$mobile-paragraph-big;
        }

        .normalParagraph,
        p,
        p > a {
            font-size: _variables.$size-paragraph-small;
        }

        .dottedLine,
        .subParagraph,
        a {
            font-size: _variables.$size-paragraph-medium;
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-l) {
    .container {
        margin: 2.5rem auto 5rem auto;

        h1,
        h1 span {
            font-size: _variables.$mobile-paragraph-big;
        }

        .normalParagraph,
        p,
        p > a {
            font-size: 0.563rem;
        }

        .dottedLine {
            margin-bottom: 0.5rem;
        }

        .boldParagraph {
            margin-bottom: 0.7rem;
        }

        .dottedLine,
        .subParagraph,
        a,
        h2 {
            font-size: _variables.$size-paragraph-small;
        }
    }
}
