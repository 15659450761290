@use "@styles/_variables" as _variables;

figure {
    background-color: _variables.$color-black;
}

.countdown {
    text-align: center;
    max-width: 32rem;
    margin-top: 14%;
    margin-left: 7.5vw;
    color: #ffffff;

    h2 {
        font-weight: 100;
        font-size: 0.9rem;
        letter-spacing: 0.1rem;
        font-family: "Montserrat-Regular", sans-serif;
        font-weight: initial;
    }

    .counter {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin-bottom: 15%;

        .number {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 5rem;
        }

        .word {
            font-family: "Montserrat-Medium", sans-serif;
            font-size: 0.8rem;
            color: _variables.$color-brown;
            font-weight: bold;
            letter-spacing: 0.15rem;
        }
    }

    .title {
        margin-top: 8rem;

        p {
            text-align: center;
            font-size: 5rem;
            font-family: "Bebas-Neue", sans-serif;
            font-weight: 300;
            line-height: 4.3rem;
            letter-spacing: 0.3rem;

            &.firstLine {
                color: _variables.$color-brown;
            }

            &.secondLine {
                letter-spacing: 0.22rem;
                word-spacing: 0.2rem;
            }

            &.thirdLine {
                font-size: 4.3rem;
                letter-spacing: 0.23rem;
                line-height: 3.5rem;
            }
        }
    }

    .symbols,
    .dropDate {
        font-family: "Montserrat-Regular", sans-serif;
    }

    .dropDate {
        margin-top: 2.5rem;
    }

    .symbols {
        font-size: 1.5rem;
        margin: 2.5rem 0;
        word-spacing: 1.5rem;
        color: #cacaca55;
    }

    .subscribe {
        font-size: 0.75rem;
        font-family: "Montserrat-Bold", sans-serif;
        display: inline-block;
        background-color: _variables.$color-brown;
        color: _variables.$color-black;
        text-decoration: none;
        width: 30.6rem;
        line-height: 3rem;
        letter-spacing: 0.09rem;
        margin-bottom: 1rem;
    }

    .traitsButton {
        background-color: #fff;
    }

    .learnMore {
        text-decoration: none;
        color: _variables.$color-brown;
        font-family: "Montserrat-Medium", sans-serif;
        font-size: 0.8rem;
        font-weight: bold;
    }

    .traistDropName {
        margin-bottom: 2.5rem;
        color: _variables.$color-brown;
        font-size: 1.7rem;
    }

    .burnNow {
        display: inline-block;
        margin: auto;

        img {
            width: 8vw;
            height: 8vw;
        }
    }
}

@media screen and (max-width: 1920px) {
    .countdown {
        margin-top: 10%;
    }
}

@media screen and (max-width: 1660px) {
    .countdown {
        max-width: 23rem;
        margin-top: 6%;
        margin-left: 7vw;

        .traistDropName {
            font-size: _variables.$size-subtitle;
        }

        h2 {
            font-size: 0.65rem;
            letter-spacing: 0.1rem;
        }

        .counter {
            .number {
                font-size: 3.5rem;
            }

            .word {
                font-size: 0.6rem;
                letter-spacing: 0.1rem;
            }
        }

        .title {
            p {
                font-size: 3.4rem;
                line-height: 3rem;
                letter-spacing: 0.3rem;

                &.secondLine {
                    letter-spacing: 0.22rem;
                    word-spacing: 0.2rem;
                }

                &.thirdLine {
                    font-size: 2.95rem;
                    letter-spacing: 0.22rem;
                    line-height: 2.5rem;
                }
            }
        }

        .dropDate {
            margin-top: 1.5rem;
            font-size: 0.675rem;
        }

        .symbols {
            font-size: 1.2rem;
            margin: 1.5rem 0;
            word-spacing: 1rem;
        }

        .subscribe {
            font-size: 0.65rem;
            width: 22rem;
            line-height: 2.5rem;
        }
    }
}

@media screen and (max-width: 1200px) {
    .countdown {
        max-width: 16rem;
        margin-top: 5%;

        .traistDropName {
            font-size: _variables.$mobile-paragraph-big;
            margin-bottom: 1.5rem;
        }

        h2 {
            font-size: 0.45em;
            letter-spacing: 0.1rem;
        }

        .counter {
            .number {
                font-size: 2.5rem;
            }

            .word {
                font-size: 0.4rem;
                letter-spacing: 0.05rem;
            }
        }

        .title {
            p {
                font-size: 2.4rem;
                line-height: 2.2rem;
                letter-spacing: 0.2rem;

                &.secondLine {
                    letter-spacing: 0.13rem;
                    word-spacing: 0.2rem;
                }

                &.thirdLine {
                    font-size: 2.2rem;
                    letter-spacing: 0.11rem;
                    line-height: 2rem;
                }
            }
        }

        .dropDate {
            margin-top: 1rem;
            font-size: 0.535rem;
        }

        .symbols {
            font-size: 0.9rem;
            margin: 0.7rem 0;
            word-spacing: 0.5rem;
        }

        .subscribe {
            font-size: 0.65rem;
            width: 15.5rem;
            line-height: 2.5rem;
        }
    }
}

@media screen and (max-width: 960px) {
    .countdown {
        max-width: 32rem;
        margin-top: 5%;
        margin-left: auto;
        margin-right: auto;

        h2 {
            letter-spacing: 0.7px;
            font-size: 0.9rem;
        }

        .traistDropName {
            font-size: _variables.$size-paragraph-medium-trait;
            margin-bottom: 1.5rem;
        }

        .counter {
            .number {
                font-size: 5rem;
            }

            .word {
                font-size: 0.8rem;
                letter-spacing: 0.15rem;
            }
        }

        .title {
            p {
                font-size: 5rem;
                font-weight: 300;
                line-height: 4.3rem;
                letter-spacing: 0.3rem;

                &.secondLine {
                    letter-spacing: 0.22rem;
                    word-spacing: 0.2rem;
                }

                &.thirdLine {
                    font-size: 4.3rem;
                    letter-spacing: 0.23rem;
                    line-height: 3.5rem;
                }
            }
        }

        .dropDate {
            font-size: 1rem;
            margin-top: 2.5rem;
        }

        .symbols {
            font-size: 1.5rem;
            margin: 1.5rem 0;
            word-spacing: 1.5rem;
        }

        .subscribe {
            font-size: 0.65rem;
            width: 30.4rem;
            line-height: 2.5rem;
        }
    }
}

@media screen and (max-width: 800px) {
    .countdown {
        max-width: 23rem;
        margin-top: 6%;

        h2 {
            font-size: 0.65rem;
            letter-spacing: 0.1rem;
        }

        .counter {
            .number {
                font-size: 3.5rem;
            }

            .word {
                font-size: 0.6rem;
                letter-spacing: 0.1rem;
            }
        }

        .title {
            p {
                font-size: 3.4rem;
                line-height: 3rem;
                letter-spacing: 0.3rem;

                &.secondLine {
                    letter-spacing: 0.22rem;
                    word-spacing: 0.2rem;
                }

                &.thirdLine {
                    font-size: 2.95rem;
                    letter-spacing: 0.22rem;
                    line-height: 2.5rem;
                }
            }
        }

        .dropDate {
            margin-top: 1.5rem;
            font-size: 0.675rem;
        }

        .symbols {
            font-size: 1.2rem;
            margin: 1rem 0;
            word-spacing: 1rem;
        }

        .subscribe {
            font-size: 0.65rem;
            width: 22rem;
            line-height: 2.5rem;
        }
    }
}

@media screen and (max-width: 640px) {
    .countdown {
        max-width: 16rem;
        margin-top: 5%;

        h2 {
            font-size: 0.45em;
            letter-spacing: 0.1rem;
        }

        .counter {
            margin-bottom: 5%;

            .number {
                font-size: 2.5rem;
            }

            .word {
                font-size: 0.4rem;
                letter-spacing: 0.05rem;
            }
        }

        .title {
            margin-top: 6rem;

            p {
                font-size: 2.4rem;
                line-height: 2.2rem;
                letter-spacing: 0.2rem;

                &.secondLine {
                    letter-spacing: 0.13rem;
                    word-spacing: 0.2rem;
                }

                &.thirdLine {
                    font-size: 2.2rem;
                    letter-spacing: 0.11rem;
                    line-height: 2rem;
                }
            }
        }

        .dropDate {
            margin-top: 1rem;
            font-size: 0.535rem;
        }

        .symbols {
            font-size: 0.9rem;
            margin: 0.5rem 0;
            word-spacing: 0.5rem;
        }

        .subscribe {
            font-size: 0.65rem;
            width: 15.5rem;
            line-height: 2.5rem;
        }
    }
}

@media screen and (max-width: 500px) {
    .countdown {
        max-width: 16rem;
        margin-top: 3%;

        h2 {
            font-size: 0.45em;
            letter-spacing: 0.1rem;
        }

        .counter {
            .number {
                font-size: 2rem;
            }

            .word {
                font-size: 0.4rem;
                letter-spacing: 0.05rem;
            }
        }

        .title {
            margin-top: 4rem;

            p {
                font-size: 2.1rem;
                line-height: 1.7rem;
                letter-spacing: 0.2rem;

                &.secondLine {
                    letter-spacing: 0.13rem;
                    word-spacing: 0.2rem;
                }

                &.thirdLine {
                    font-size: 1.94rem;
                    letter-spacing: 0.11rem;
                    line-height: 1.6rem;
                }
            }
        }

        .dropDate {
            margin-top: 0.6rem;
            font-size: 0.535rem;
            line-height: 1rem;
        }

        .symbols {
            font-size: 0.9rem;
            margin: 0.3rem 0;
            word-spacing: 0.5rem;
        }

        .subscribe {
            font-size: 0.65rem;
            width: 14.1rem;
            line-height: 2.5rem;
        }
    }
}

@media screen and (max-width: 360px) {
    .countdown {
        margin-top: 1%;

        .symbols {
            margin-bottom: 3%;
        }

        .title {
            margin-top: 1.5rem;
        }
    }
}
