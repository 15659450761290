@use "@styles/_variables" as _variables;

.container {
    padding-bottom: 15rem;
    background-color: _variables.$color-black;
}

.title,
.subtitle,
.disclaimer {
    text-align: center;
    color: _variables.$color-white;
}

.title {
    margin: 3rem 0 1.5rem;
    color: _variables.$color-brown;
    font-size: _variables.$size-title-big;
    font-weight: 400;
}

.subtitle {
    margin-bottom: 5rem;
}

.burnSubitle {
    margin-bottom: 1rem;
}

.assetsToBurn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 5rem;
    color: _variables.$color-white;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 5rem;

    .goBack,
    .burn {
        width: 6.5rem;
        height: 6.5rem;
    }

    .goBack {
        background-color: _variables.$color-black;
        border-color: _variables.$color-white;
    }
}

.disclaimer {
    font-size: _variables.$size-span-small;
    font-weight: bold;
}
