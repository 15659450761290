@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    width: 100%;
    margin: auto;
}

.content {
    position: relative;
    display: grid;
    grid-template-areas:
        "tab   tab   tab   preview preview"
        "table table table preview preview";
    grid-template-rows: 4.5rem 1fr;

    max-width: _variables.$inner-content-max-width;
    margin: auto;
    padding: 1.5rem;
    z-index: 2;
    background-color: _variables.$color-white;
}

.banner {
    z-index: 1;
}
