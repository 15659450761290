@use "@styles/_mixins" as _mixins;
@use "@styles/_variables" as _variables;

.container {
    @include _mixins.flex_container(column, flex-end, center);
    width: 100%;
    min-height: _variables.$min-height-main;
    background-color: _variables.$color-solid-pink;
    padding-top: 10rem;

    .code {
        @include _mixins.flex_container(row, center, center);
        margin-bottom: 2rem;

        img {
            width: 20rem;

            &:nth-child(1) {
                margin-top: -6rem;
            }

            &:nth-child(3) {
                margin-top: -6rem;
            }
        }
    }

    .message {
        margin-bottom: 2rem;

        span {
            color: _variables.$color-brown;
        }
    }

    .decoration {
        @include _mixins.flex_container(row, flex-start, center);
        width: 46rem;

        img {
            width: 17rem;
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        padding-top: 7rem;
        .code {
            img {
                width: 13rem;
            }
        }

        .message {
            font-size: _variables.$size-paragraph-medium;
        }

        .decoration {
            width: 30rem;

            img {
                width: 10rem;
            }
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-l) {
    .container {
        .code {
            img {
                width: 5rem;

                &:nth-child(1) {
                    margin-top: -3rem;
                }

                &:nth-child(3) {
                    margin-top: -3rem;
                }
            }
        }

        .message {
            text-align: center;
            font-size: _variables.$size-paragraph-very-small;

            span {
                font-size: _variables.$size-paragraph-very-small;
            }
        }

        .decoration {
            width: 10rem;

            img {
                width: 4rem;
            }
        }
    }
}
