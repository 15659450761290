@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.btn {
    width: 6rem;
    height: 6rem;
}

.container {
    @include _mixins.flex_container(column, center, center);
    width: 100%;
    height: auto;
    padding-bottom: _variables.$bottom-space-footer;

    a {
        @include _mixins.btn_primary(_variables.$color-solid-pink, _variables.$color-white);
        @extend .btn;
        text-decoration: none;
        text-align: center;
        font-size: _variables.$size-button-small;
    }

    .upperContainer {
        @include _mixins.flex_container(row, space-between, flex-start);
        width: _variables.$inner-content-max-width;
        margin: 5rem 0 4rem 0;

        .traitImage {
            width: 20rem;
        }

        p {
            margin-bottom: 1rem;

            &.traitName {
                text-transform: uppercase;
            }
        }

        .traitPercent {
            color: _variables.$color-brown;
            margin-top: 2rem;
        }

        > div {
            @include _mixins.flex_container(column, center, center);
            margin: 0;
            flex: 4;

            &:nth-child(2) {
                flex: 0.5;
                align-self: center;
            }
        }
    }

    .lowerContainer {
        @include _mixins.flex_container(row, space-between, center);
        width: _variables.$inner-content-max-width;
        margin-bottom: 2rem;

        > div {
            align-self: baseline;
            flex: 2;
            height: auto;

            p {
                text-align: justify;

                span {
                    color: _variables.$color-brown;
                }
            }

            &:nth-child(2) {
                @include _mixins.flex_container(row, flex-end, flex-end);
                flex: 0.5;
                align-self: center;

                a {
                    @include _mixins.btn_primary(_variables.$color-brown, _variables.$color-white);
                    @extend .btn;
                    font-size: _variables.$size-button-small;
                }
            }
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        padding: 2rem;

        .upperContainer {
            @include _mixins.flex_container(column, flex-start, center);
            width: 100%;
            margin: 0 0 3rem 0;

            .traitImage {
                width: 18rem;
            }

            .placeHolder {
                height: 20rem;
            }

            .traitPercent,
            p {
                font-size: _variables.$mobile-paragraph-medium;
            }

            .traitPercent {
                margin: 2rem 0;
            }

            > div {
                &:nth-child(2) {
                    align-self: center;
                }
            }
        }

        .lowerContainer {
            @include _mixins.flex_container(column, center, center);
            width: 100%;
            margin-top: 0;

            p {
                margin-bottom: 2rem;
            }

            p,
            span {
                font-size: _variables.$mobile-paragraph-medium;
            }

            div {
                width: 100%;

                &:nth-child(2) {
                    justify-content: center;
                }
            }
        }
    }
}
