@use "@styles/_variables" as _variables;

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
        font-size: _variables.$size-paragraph-medium-trait;
    }

    .button {
        width: 3.5rem;
        height: 3.5rem;
        font-size: _variables.$size-button-very-small;
        line-height: 0.8rem;
    }
}
