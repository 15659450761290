@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    @include _mixins.flex_container(row, center, center);
    width: 100%;
    padding: 2rem 0.5rem 1rem 2.8rem;

    &.containerBrown {
        background-color: _variables.$color-background-simulator;
        height: _variables.$collection-height;
    }

    &.containerGray {
        background-color: _variables.$color-dark-gray;
        height: _variables.$collection-height;
    }
}

.disabled {
    pointer-events: none;
    background-color: _variables.$color-medium-gray;
    border-color: _variables.$color-medium-gray;
    cursor: no-drop;
}

.filterBox {
    @include _mixins.flex_container(column, space-between, unset);
    height: 100%;
}

.infiniteContainer {
    width: 100%;
    height: 100%;
    align-self: baseline;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 0.2rem;
    }

    &::-webkit-scrollbar-track {
        background: _variables.$color-transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: _variables.$color-black;
    }
}

@media screen and (max-width: #{_variables.$media-laptop-lm}) {
    .container {
        padding: 2rem 0.5rem 1rem 2rem;

        &.containerBrown {
            height: _variables.$collection-height-mid;
        }

        &.containerGray {
            height: _variables.$collection-height-mid;
        }
    }
}
