@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    @include _mixins.flex_container(row, space-between, center);
    position: fixed;
    width: 100%;
    height: _variables.$height-header;
    padding: 0.75rem 0rem 0.75rem 2rem;
    background: _variables.$color-black;
    z-index: 999;

    .content {
        @include _mixins.flex_container(row, space-between, center);

        div {
            > a {
                @include _mixins.btn_primary(_variables.$color-brown, _variables.$color-white);
            }

            .hackTheRoyalsButton,
            .hackTheRoyalsLink,
            .subversiveHierarchy {
                @include _mixins.btn_primary(_variables.$color-solid-pink, _variables.$color-white);
                font-size: _variables.$size-paragraph-medium;
                line-height: 1.1rem;
            }

            a,
            button {
                line-height: 1.1rem;
            }
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        display: none;
    }
}
