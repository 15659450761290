@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    @include _mixins.flex_container(column, center, center);
    width: _variables.$inner-content-max-width;
    height: 100%;
    margin: auto;
    padding-top: 5rem;
    padding-bottom: _variables.$bottom-space-footer;

    h1 {
        color: _variables.$color-brown;
        align-self: start;
        margin-bottom: 2rem;
    }

    section {
        @include _mixins.flex_container(row, center, center);
        margin: 1rem 0;

        img,
        a,
        button {
            flex: 1;
            max-width: 6.5rem;
        }

        a,
        button {
            height: 6.5rem;
            line-height: 1.1rem;
        }

        article {
            flex: 4;
            padding-left: 5.5rem;
        }

        h2 {
            color: _variables.$color-brown;
            font-size: _variables.$size-subtitle;
        }

        p {
            text-align: justify;
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        width: 100%;
        padding: 2rem 2rem;

        h1 {
            font-size: _variables.$mobile-size-title-big;
        }

        section {
            @include _mixins.flex_container(column, center, center);

            img,
            a,
            button {
                flex: none;
                max-height: 5.5rem;
                font-size: _variables.$mobile-paragraph-small;
            }

            a,
            button {
                width: 5.5rem;
                line-height: 0.9rem;
            }

            article {
                padding-left: 0;
            }

            h2 {
                margin-top: 1rem;
            }

            p {
                margin-bottom: 2rem;
            }

            h2,
            p {
                font-size: _variables.$mobile-paragraph-medium;
            }
        }
    }
}
