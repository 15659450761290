@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    @include _mixins.flex_container(row, center, center);
    position: relative;
    width: 17rem;
    height: 17rem;
    margin: auto;

    .outer,
    .inner {
        position: absolute;
        max-width: 16rem;
    }

    .outer {
        animation: spin 1s linear infinite;
    }

    .inner {
        max-width: 13rem;
    }

    .inner__invert {
        max-width: 5.5rem;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        padding: 2rem;
        @include _mixins.flex_container(column, flex-start, center);

        > div {
            margin-top: 4rem;
        }

        div,
        img {
            width: 25vw;
            height: 25vw;
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-m) {
    .container {
        > p {
            font-size: _variables.$mobile-paragraph-small;
        }

        div,
        img {
            width: 50vw;
            height: 50vw;
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-s) {
    .container {
        div,
        img {
            width: 55vw;
            height: 55vw;
        }
    }
}
