@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    width: 100%;
    height: auto;
    @include _mixins.flex_container(column, center, center);
    padding-top: 5rem;
    padding-bottom: _variables.$bottom-space-footer;

    > h1 {
        width: 100%;
        margin-bottom: 2rem;
        max-width: _variables.$inner-content-max-width;
    }

    h1,
    h2 {
        color: _variables.$color-brown;
    }

    section {
        max-width: _variables.$inner-content-max-width;

        h2 {
            margin-bottom: 1.2rem;
            font-size: _variables.$size-font-auxiliar-text;
        }

        p {
            margin-bottom: 2rem;

            span {
                color: _variables.$color-brown;
                font-weight: bold;
            }
        }

        .decimalList {
            li {
                list-style-type: decimal;
            }
        }

        .lowerLatinList {
            li {
                list-style-type: lower-latin;
            }
        }

        .upperLatinList {
            li {
                list-style-type: upper-latin;
            }
        }

        .discList {
            list-style-type: disc;
        }

        .decimalList,
        .lowerLatinList,
        .upperLatinList,
        .discList {
            padding-left: 3rem;
        }

        li {
            list-style-position: inside;
            margin-bottom: 2rem;
        }

        li,
        li span {
            color: _variables.$color-solid-pink;
            font-weight: bold;
        }

        li p {
            display: inline;
            color: _variables.$color-black;
            font-weight: normal;
        }

        > ol,
        > li,
        p,
        h2 {
            text-align: justify;
            width: _variables.$inner-content-max-width;
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-l) {
    .container {
        width: 100%;
        margin: auto;
        padding: 2rem;

        h1 {
            width: 100%;
        }

        section {
            width: 100%;
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        > h1 {
            font-size: _variables.$mobile-size-title-big;
            margin-bottom: 1rem;
        }

        section {
            p {
                margin-bottom: 1.5rem;
            }

            h2,
            p,
            li,
            span {
                font-size: _variables.$mobile-paragraph-medium;
            }
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-l) {
    .container {
        section {
            p,
            h2,
            ol {
                width: 100%;
            }
        }
    }
}
