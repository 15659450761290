@use "@styles/_variables" as _variables;
@use "@styles/_placeholders";

.placeHolder {
    width: 5rem;
    height: 5rem;
    @extend %placeholder_animation;
}

.noTraits {
    text-align: center;
    grid-column: 1 / span 3;
}

.container {
    width: 100%;
    height: 100%;
    max-height: calc(_variables.$collection-height - 7.9rem);
}

.traitBox {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    overflow: clip;

    .traitItem {
        width: inherit;
        height: inherit;
    }

    img {
        width: 100%;
        height: 100%;
    }

    .traitTaken {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url("~@images/attached-trait-icon.png");
        background-size: contain;
        background-repeat: no-repeat;
    }

    &.traitDisabled {
        img {
            cursor: not-allowed;
            filter: grayscale(100%);
        }
    }

    &.traitSelected {
        outline: 0.2rem solid _variables.$color-black;
        outline-offset: -0.1875rem;
        filter: opacity(80%);
    }

    &.selectedForOpensea {
        outline: 0.2rem solid _variables.$color-white;
        outline-offset: -0.1875rem;
    }
}

.infiniteScroll {
    width: 100%;
    display: grid;
    padding-right: 0.5rem;
    grid-template-columns: 5rem 5rem 5rem;
    gap: 0.5rem;
    justify-content: end;
    align-self: baseline;
}

.infiniteLoader {
    text-align: end;
    padding-right: 5rem;
    margin-top: 1rem;
}

.loader {
    display: block;
    width: 100%;
    grid-column: 1 / span 3;
}

@media screen and (max-width: #{_variables.$media-laptop-lm}) {
    .infiniteScroll {
        grid-template-columns: 4rem 4rem 4rem;
    }
}
