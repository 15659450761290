@use "./variables" as _variables;

%btn_base {
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: _variables.$size-button-medium;
    padding: 0 1rem;
    line-height: 1.5rem;
    width: 7.2rem;
    height: 7rem;
    font-family: _variables.$font-montserrat-medium;
    background-color: _variables.$color-transparent;
    text-align: center;
    text-decoration: none;
}

%btn_stage {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: _variables.$font-montserrat-medium;
    background-color: _variables.$color-black;
    color: _variables.$color-white;
    border: none;
    font-size: _variables.$size-button-medium;
    height: 2.5rem;
    padding: 0 2rem;
    width: 6.875rem;
    text-decoration: none;
    text-align: center;
}

%btn_quantity_selector {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: _variables.$font-montserrat-medium;
    background-color: transparent;
    font-weight: bold;
    border: none;
    color: _variables.$color-black;
    font-size: 2rem;
    height: 2.5rem;
    padding: 0 1rem;
    text-decoration: none;
    cursor: pointer;
}

%btn_quantity_selector_disabled {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: _variables.$font-montserrat-medium;
    background-color: transparent;
    font-weight: bold;
    border: none;
    color: _variables.$color-medium-gray;
    font-size: 2rem;
    height: 2.5rem;
    padding: 0 1rem;
    text-decoration: none;
    cursor: no-drop;
}

%placeholder_animation {
    background: linear-gradient(
        45deg,
        #cdcdcd,
        #cdcdcd,
        #cdcdcd,
        #cdcdcd,
        #cdcdcd,
        #cdcdcd,
        #ffffff,
        #cdcdcd,
        #cdcdcd,
        #cdcdcd,
        #cdcdcd,
        #cdcdcd,
        #ffffff
    );
    -webkit-animation: loading 1s linear infinite;
    animation: loading 1s linear infinite;
    background-size: 400% 400%;
}

@-webkit-keyframes loading {
    0% {
        background-position: left;
    }
    100% {
        background-position: right;
    }
}

@keyframes loading {
    0% {
        background-position: left;
    }
    100% {
        background-position: right;
    }
}
