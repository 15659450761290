@use "@styles/_variables" as _variables;

.container {
    grid-area: tab;
    width: 30rem;
}

.tabs {
    width: 100%;
    height: 100%;
    display: flex;
}

.tab {
    flex: 1;
    height: 100%;
    padding: 0.5rem;
    text-align: center;
    background-color: _variables.$color-ranking-tab;
    cursor: pointer;

    &.active {
        background-color: _variables.$color-brown;
    }

    p {
        font-size: _variables.$size-paragraph-small;
        color: _variables.$color-white;
    }
}

.visible {
    display: block;
}

.hidden {
    display: none;
}

.searchForm {
    position: relative;
    width: 10rem;
    margin: 0 auto;

    .searchInput {
        width: 100%;
        border: 0;
        outline: 0;
        appearance: none;
        padding: 0.1rem 2.5rem 0.1rem 0.2rem;
        -webkit-appearance: none;
        -moz-appearance: textfield;

        &:focus {
            outline: none;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    button {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        padding-right: 0.1rem;
        color: _variables.$color-white;
        font-size: _variables.$size-paragraph-small;
        background-color: _variables.$color-transparent;
        border: none;
        cursor: pointer;
        display: flex;

        > img {
            width: 80%;
        }

        &.cross {
            right: 1.1rem;
            padding-right: 0;

            img {
                width: 0.5rem;
                margin-bottom: 0.1rem;
            }
        }
    }
}
