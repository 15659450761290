@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    @include _mixins.flex_container(column, center, center);
    width: 100%;
    height: auto;
    padding-top: 5rem;
    padding-bottom: _variables.$bottom-space-footer;

    h1,
    h2 {
        color: _variables.$color-brown;
        text-align: start;
        margin-bottom: 2rem;
        width: _variables.$inner-content-max-width;
    }

    section {
        h3 {
            color: _variables.$color-solid-pink;
            margin-bottom: 1.2rem;
            font-size: _variables.$size-font-auxiliar-text;
        }

        p,
        ul {
            margin-bottom: 4rem;
        }

        .ulParagraph {
            margin-bottom: 0;
        }

        p a,
        ul li a {
            text-decoration: none;
            color: _variables.$color-brown;
        }

        li {
            list-style-position: inside;
            font-size: _variables.$size-paragraph-big;
            letter-spacing: 0.1rem;
            line-height: 1.7rem;
        }

        h3,
        p,
        li {
            text-align: justify;
            width: _variables.$inner-content-max-width;
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        width: 100%;
        padding: 2rem 2rem;

        h1,
        h2 {
            width: 100%;
            font-size: _variables.$mobile-size-title-big;
            margin-bottom: 1rem;
        }

        section {
            width: 100%;

            h3,
            p,
            li {
                font-size: _variables.$mobile-paragraph-medium;
                width: 100%;
            }

            p,
            ul {
                margin-bottom: 1.5rem;
            }

            h3 {
                margin-bottom: 0.5rem;
            }
        }
    }
}
