@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    @include _mixins.flex_container(column, center, center);
    width: 100%;
    height: auto;

    .mintedTraitText {
        margin: 5rem 0 3rem 0;
        max-width: _variables.$inner-content-max-width;
        text-align: center;

        span {
            display: block;
            color: _variables.$color-brown;
            margin-bottom: 0;
        }

        &:not(:first-child) {
            margin: 0;
        }
    }

    .claimedTraitContainer {
        z-index: 1;
        min-height: 10rem;
    }

    .loader {
        margin-bottom: 5rem;
        margin-top: 0;
        padding: 0;

        div {
            margin-top: 0;
            padding: 0;
        }
    }

    .onlyDesktop {
        display: block;
    }

    .check {
        margin-bottom: 3rem;
        max-width: 16rem;
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        > p {
            max-width: 100%;
            margin: 0 0 1.5rem 0;
            font-size: _variables.$mobile-paragraph-medium;

            span {
                font-size: _variables.$mobile-paragraph-medium;
            }
        }

        .onlyDesktop {
            display: none;
        }
    }

    .check {
        margin-bottom: 3rem;
        max-width: 16rem;
    }
}

@media screen and (max-width: _variables.$media-mobile-l) {
    .container {
        .check {
            margin-bottom: 5rem;
            max-width: 14rem;
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-m) {
    .container {
        p,
        p span {
            font-size: _variables.$mobile-paragraph-small;
            line-height: 1.5rem;
        }

        .check {
            margin-bottom: 2rem;
            max-width: 13rem;
        }
    }
}
