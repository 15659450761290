@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.btn {
    width: 6rem;
    height: 6rem;
}

.container {
    @include _mixins.flex_container(column, center, center);
    width: 100%;
    height: auto;

    .traitContainer {
        display: grid;
        grid-template-areas:
            ". traitName . ."
            ". traitImage . ."
            ". traitImage traitPercent traitPercent"
            ". traitImage hackTheRoyalsLink openSeaLink";
        grid-template-columns: 2fr 2fr 1fr 1fr;
        width: _variables.$inner-content-max-width;
        position: relative;
        background-color: _variables.$color-white;
        padding: 1.5rem 1.5rem 0 1.5rem;

        .carouselContainer {
            width: 22rem;
            grid-area: traitImage;
        }

        p {
            margin-bottom: 1rem;

            &.traitName {
                text-transform: uppercase;
                grid-area: traitName;
            }
        }

        .arrows {
            margin-top: 2rem;
        }

        .traitPercent {
            margin-left: auto;
            color: _variables.$color-brown;
            margin-top: 2rem;
            grid-area: traitPercent;
            font-size: _variables.$size-paragraph-small-trait;
        }

        .hackTheRoyalsLink,
        .openSeaLink {
            @extend .btn;
            text-decoration: none;
            text-align: center;
            font-size: _variables.$size-button-small;
            margin-bottom: 1.3rem;
            justify-self: end;
        }

        .hackTheRoyalsLink {
            @include _mixins.btn_primary(_variables.$color-brown, _variables.$color-white);
            grid-area: hackTheRoyalsLink;
        }

        .openSeaLink {
            @include _mixins.btn_primary(_variables.$color-black, _variables.$color-white);
            grid-area: openSeaLink;
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        padding: 0;

        .traitContainer {
            width: 100%;
            display: grid;
            grid-template-areas:
                "traitName         traitName"
                "traitImage        traitImage"
                "traitPercent      traitPercent"
                "hackTheRoyalsLink openSeaLink";
            grid-template-columns: 1fr;

            .traitPercent {
                width: 100%;
                margin-top: 1rem;
                margin-bottom: 2rem;
                text-align: center;
            }

            .hackTheRoyalsLink,
            .openSeaLink {
                justify-self: start;
            }
        }
    }
}
