@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.loader {
    margin-top: 5rem;
}

.fullRow {
    width: 100%;
    display: block;
    border: none;

    td {
        height: 100%;
        border: none;
        width: 100%;
    }

    .notFound {
        width: 100%;
        text-align: center;
        margin-top: 2rem;
        font-size: _variables.$size-paragraph-small;
        color: _variables.$color-white;
    }
}

.container {
    @include _mixins.flex_container(column, center, center);
    padding-top: 5rem;
    background-color: _variables.$color-black;

    a {
        width: 6.5rem;
        height: 6.5rem;
        text-align: center;
        color: _variables.$color-white;
        background-color: _variables.$color-brown;
        padding: 0 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
    }
}

thead,
tbody {
    display: block;
}

tbody {
    height: 37.6rem;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 0.2rem;
    }

    &::-webkit-scrollbar-track {
        background: _variables.$color-transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: _variables.$color-white;
    }
}

.title {
    text-align: center;
    color: _variables.$color-white;

    span {
        color: _variables.$color-brown;
    }
}

.table {
    grid-area: table;
    width: 30rem;
    border-collapse: collapse;
    margin-top: 3rem;

    thead {
        display: block;
    }

    th {
        font-weight: normal;
    }
}

.tableHeader {
    @include _mixins.flex_container(row, space-around, center);
    background-color: _variables.$color-black;
    color: _variables.$color-brown;
    padding: 0.5rem 0;
    font-size: _variables.$size-paragraph-very-small-trait;
    font-weight: normal;
}

.preview {
    width: 3.76rem;
}

.name,
.date {
    flex: 2;
}

.wallet {
    flex: 4;
    width: 18rem;
}

.row {
    width: 100%;
    display: flex;
    text-align: center;
    height: 3.76rem;

    &:hover {
        background-color: _variables.$color-brown;
    }

    td {
        @include _mixins.flex_container(row, center, center);
        border-left: 0.0625rem solid _variables.$color-white;
        border-bottom: 0.0625rem solid _variables.$color-white;
        font-family: _variables.$font-montserrat-medium;
        font-size: _variables.$size-paragraph-medium-trait;
        color: _variables.$color-white;

        &.wallet {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            line-height: 3.76rem;
            padding: 0 0.5rem;
        }

        &.name {
            border-left: none;
        }
    }

    .preview {
        display: inline-table;
        width: 3.76rem;
        background-color: _variables.$color-gray-light;

        img {
            width: 100%;
            display: block;
        }
    }
}

.pagerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem 0;
    border: none;
    color: _variables.$color-white;

    span {
        font-size: _variables.$size-paragraph-small;
    }

    .nextBtn,
    .prevBtn {
        @include _mixins.flex_container(row, center, center);
        border: none;
        background-color: _variables.$color-white;
        cursor: pointer;
        width: 0.5rem;
        margin: 0 0.5rem;
        height: 1rem;

        img {
            width: 60%;
        }
    }

    .nextBtn {
        transform: rotate(180deg);
    }

    .prevBtn {
        margin-left: 0;
    }

    select {
        font-size: _variables.$size-paragraph-small;
    }
}
