@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    width: 100%;
    display: flex;
    text-align: center;
}

td {
    @include _mixins.flex_container(row, center, center);
    border-left: 0.0625rem solid _variables.$color-black;
    border-bottom: 0.0625rem solid _variables.$color-black;
    font-family: _variables.$font-montserrat-medium;
    font-size: _variables.$size-paragraph-medium-trait;
    height: 3.76rem;
}

.selected {
    background-color: _variables.$color-brown-42;
}

.avid {
    border-left: none;
}

.viewop {
    border-right: none;
}

.avid,
.types {
    flex: 3;
}

.types {
    option,
    select {
        width: 6.2rem;
        text-transform: uppercase;
    }
}

.preview {
    display: inline-table;
    width: 3.76rem;
    background-color: _variables.$color-gray-light;

    img {
        width: 100%;
        display: block;
    }
}

.rank,
.score,
.viewop {
    flex: 2;

    a {
        @include _mixins.flex_container(row, center, center);
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
        border: none;
        background-color: _variables.$color-brown;
    }
}
