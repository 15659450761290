@use "@styles/_variables" as _variables;

.buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5rem;
    margin-top: auto;

    button,
    a {
        width: 6.5rem;
        height: 6.5rem;
        text-align: center;
        color: _variables.$color-white;
    }

    a {
        background-color: _variables.$color-black;
        padding: 0 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
