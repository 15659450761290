@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    ul {
        list-style: none;
        @include _mixins.flex_container(row, flex-start, center);
        margin-right: 2.5rem;

        > .separator {
            color: _variables.$color-white;
        }

        > li {
            font-size: _variables.$size-link;
            margin: 0 0.35rem;

            > a {
                text-decoration: none;
                color: _variables.$color-white;

                &:hover {
                    color: _variables.$color-brown;
                }
            }

            > .activeLink {
                color: _variables.$color-brown;
            }
        }
    }
}

@media only screen and (max-width: _variables.$media-laptop-ml) {
    .container {
        ul {
            li {
                font-size: 0.8rem;
            }
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        width: 100%;
        height: calc(100vh - 5rem);
        margin-top: 5rem;
        background-color: _variables.$color-white;
        position: fixed;
        z-index: 999;

        > ul {
            margin: 0 1.5rem;
            @include _mixins.flex_container(column, center, center);

            > .separator {
                display: none;
            }

            > li {
                width: 100%;
                padding: 1.5rem 0;
                text-align: center;
                border-bottom: 0.025rem solid _variables.$color-light-gray;
                font-size: _variables.$size-link;

                > a {
                    color: _variables.$color-black;
                }
            }
        }
    }
}
