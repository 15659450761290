@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    width: 100%;
    height: auto;
    @include _mixins.flex_container(column, center, center);
    padding-top: 5rem;
    padding-bottom: _variables.$bottom-space-footer;

    p,
    h3 {
        margin-bottom: 2rem;
        width: 100%;
        max-width: _variables.$inner-content-max-width;
    }

    h3 {
        font-size: _variables.$size-paragraph-big;
    }

    > h1 {
        width: 100%;
        margin-bottom: 2rem;
        max-width: _variables.$inner-content-max-width;
    }

    h1,
    h2 {
        color: _variables.$color-brown;
    }

    h2 {
        margin-bottom: 1rem;
    }

    li {
        margin-bottom: 0.7rem;
        margin-left: 1rem;
    }

    section {
        max-width: _variables.$inner-content-max-width;

        > ol,
        > li,
        p,
        h2 {
            text-align: justify;
            width: _variables.$inner-content-max-width;
        }

        .lowerLatinList {
            li {
                list-style-type: lower-latin;
            }
        }

        .lowerRomanList {
            li {
                list-style-type: lower-roman;
            }
        }

        .squareList {
            li {
                list-style-type: square;
            }
        }

        .discList {
            li {
                list-style-type: disc;
            }
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-l) {
    .container {
        width: 100%;
        margin: auto;
        padding: 2rem;

        h1 {
            width: 100%;
        }

        section {
            width: 100%;
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        > h1 {
            font-size: _variables.$mobile-size-title-big;
            margin-bottom: 1rem;
        }

        section {
            p {
                margin-bottom: 1.5rem;
            }

            h2,
            p,
            li,
            span {
                font-size: _variables.$mobile-paragraph-medium;
            }
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-l) {
    .container {
        section {
            p,
            h2,
            h3,
            ol,
            ul {
                width: 100%;
            }

            a {
                overflow-wrap: break-word;
            }
        }
    }
}
