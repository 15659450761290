// Colors
$color-white: #ffffff;
$color-gray-light: #e6e7e9;
$color-black: #000000;
$color-medium-gray: #c4c4c4;
$color-light-gray: #747474;
$color-gray: #231f1e;
$color-dark-gray: #57585a;
$color-brown: #c7974f;
$color-brown-42: rgba(199, 151, 79, 0.42);
$color-solid-pink: #772e3f;
$color-burning: #f5252f;
$color-transparent: transparent;
$color-box-shadow: rgba(0, 0, 0, 0.25);
$color-background-simulator: #deac5a;
$color-gray-loader: #706f6f;
$color-ranking-tab: #7f7f7f;

// Fonts
$font-montserrat-medium: "Montserrat", Arial, Helvetica, sans-serif;
$font-bebas-neues: "Bebas-Neue", Arial, Helvetica, sans-serif;

// Background bottom banner
$background-bottom-banner: 7.5rem;

// Containers
$max-width-header: 85rem;
$max-width-main: 80rem;
$height-footer: 27rem;
$height-header: 7rem;
$min-height-main: calc(100vh - #{$height-footer} - #{$height-header});

// Titles
$size-title-big: 2.5rem;
$size-title-medium: 1.5rem;
$size-subtitle: 1.25rem;

//Paragraphs
$size-paragraph-very-small: 0.62rem;
$size-paragraph-small: 0.75rem;
$size-paragraph-medium: 0.9rem;
$size-paragraph-big: 1.125rem;
$size-paragraph-small-trait: 0.9rem;
$size-paragraph-medium-trait: 0.875rem;
$size-paragraph-very-small-trait: 0.625rem;
$size-paragraph-progress-bar: 1.1rem;

//Mobile
$mobile-size-title-big: 1.25rem;
$mobile-paragraph-trait: 0.75rem;
$mobile-paragraph-big: 1rem;
$mobile-paragraph-medium: 0.875rem;
$mobile-paragraph-small: 0.75rem;
$mobile-font-footer: 0.65rem;
$mobile-height-footer: 31rem;
$mobile-s-height-footer: 35rem;
$height-header-mobile: 5rem;

//Links
$size-link: 1rem;
$size-link-mobile: 1rem;

//Span
$size-span-small: 1rem;
$size-span-medium: 1.1rem;

//Labels
$size-label: 1.125rem;

//Buttons
$size-button-small: 0.9rem;
$size-button-medium: 1rem;
$size-button-very-small: 0.5rem;

//Inputs
$size-inputs: 1.1rem;
$size-font-footer: 0.781rem;
$size-font-auxiliar-text: 1.1rem;

// Media queries resolutions
$media-laptop-l: 1440px;
$media-laptop-lm: 1360px;
$media-laptop-ml: 1280px;
$media-laptop-m: 1024px;
$media-mobile-l: 768px;
$media-mobile-m: 425px;
$media-mobile-s: 375px;

// Inner Content max-width
$inner-content-max-width: 56rem;
$inner-content-correction: 2.3rem;

// Space between elements
$top-bottom-space: 6.125rem;
$bottom-space-footer: 4.5rem;

// Simulator
$collection-height: 50.6rem;
$collection-height-mid: 39.4rem;
