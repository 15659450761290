@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.buyOpensea {
    @include _mixins.btn_primary(_variables.$color-black, _variables.$color-white);
    width: 6rem;
    height: 6rem;
    text-transform: uppercase;
    align-self: baseline;
    font-size: _variables.$size-paragraph-small;
    line-height: unset;
}

@media screen and (max-width: #{_variables.$media-laptop-lm}) {
    .buyOpensea {
        width: 5rem;
        height: 5rem;
        font-size: _variables.$size-paragraph-very-small;
    }
}
