@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    width: 100%;
    height: auto;
    @include _mixins.flex_container(column, center, center);
    padding-top: 5rem;
    padding-bottom: _variables.$bottom-space-footer;

    img {
        max-width: 25rem;
        margin-left: 9rem;
        margin-top: 3rem;
    }
}

@media screen and (max-width: _variables.$media-mobile-l) {
    .container {
        margin: auto;
        padding: 2rem;

        img {
            max-width: 15rem;
            margin-left: 5rem;
        }
    }
}
