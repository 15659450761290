@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.check {
    margin-bottom: 5rem;
    max-width: 16rem;
}

.detailContainer {
    @include _mixins.flex_container(row, flex-start, center);
    width: auto;
    padding: 0 1.5rem 0 0;
    margin-bottom: 0;
    background-color: _variables.$color-white;
    z-index: 1;

    .carousel {
        width: 25.6rem;
    }

    .traitsContainer {
        width: 20rem;
        margin-left: 0.5rem;
        align-self: flex-end;
        padding-bottom: 1rem;

        .trait {
            padding: 0.07rem 0;

            &:not(:last-child) {
                border-bottom: 0.1rem solid _variables.$color-light-gray;
            }

            p {
                @include _mixins.flex_container(row, space-between, center);
                width: 100%;
                text-transform: uppercase;

                span {
                    font-size: _variables.$size-paragraph-small-trait;
                }
            }
        }
    }

    .btnContainer {
        align-self: end;
        margin: 0 0 1.2rem 2rem;

        a {
            @include _mixins.btn_primary(_variables.$color-black, _variables.$color-white);
            font-size: _variables.$size-button-small;
            width: 6rem;
            height: 6rem;
            line-height: 1.1rem;
        }
    }
}
