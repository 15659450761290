@use "@styles/_mixins" as _mixins;
@use "@styles/_variables" as _variables;
@use "@styles/_placeholders";

.primaryHeader {
    @include _mixins.btn_primary(_variables.$color-brown, _variables.$color-white);
}

.secondaryHeader {
    @include _mixins.btn_primary(_variables.$color-solid-pink, _variables.$color-white);
}

.tertiaryHeader {
    @include _mixins.btn_primary(_variables.$color-dark-gray, _variables.$color-white);
}

.quaternaryHeader {
    @include _mixins.btn_primary(_variables.$color-black, _variables.$color-white);
}

.link {
    cursor: pointer;
    @include _mixins.btn_link(_variables.$color-white);
}

.stage {
    @extend %btn_stage;
}

.quantity_selector {
    @extend %btn_quantity_selector;
}

.quantity_selector_disabled {
    @extend %btn_quantity_selector_disabled;
}
