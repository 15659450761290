@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.loader {
    margin: 2rem 0;
}

.container {
    @include _mixins.flex_container(column, center, center);
    width: 100%;
    height: auto;
    padding-top: 5rem;

    a {
        color: _variables.$color-brown;
        text-decoration: none;
        cursor: pointer;
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        width: 100%;
        padding: 2rem;

        > p {
            max-width: 100%;
            margin: 0 0 1.5rem 0;
            font-size: _variables.$mobile-paragraph-medium;

            a {
                font-size: _variables.$mobile-paragraph-medium;
            }
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-m) {
    .container {
        p,
        p a {
            font-size: _variables.$mobile-paragraph-small;
            line-height: 1.5rem;
        }
    }
}
