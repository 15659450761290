@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    width: 100%;
    padding: 2rem 2rem 0 2rem;

    p {
        font-size: _variables.$size-paragraph-big;
        font-family: _variables.$font-montserrat-medium;
        margin-bottom: 1.5rem;
        text-align: center;
    }

    .title {
        width: 100%;
        text-align: center;
        margin-bottom: 4rem;
        font-size: _variables.$size-title-medium;
        color: _variables.$color-brown;
    }

    .bolded {
        font-weight: bold;
    }

    .noMarginTop {
        margin-top: 0;
    }

    .noMarginBottom {
        margin-bottom: 0;
    }

    .confirmButton {
        margin: 4rem auto;
        text-transform: uppercase;
    }

    .links {
        text-align: center;
        text-decoration: none;
        color: _variables.$color-brown;
        font-size: _variables.$size-paragraph-big;
    }

    .closeBox {
        @include _mixins.flex_container(row, flex-end, center);
        margin-bottom: 1rem;

        button {
            cursor: pointer;
            background-color: transparent;
            border: none;

            img {
                display: block;
            }
        }
    }
}
