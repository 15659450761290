@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.freezeContainer {
    @include _mixins.flex_container(column, center, center);
    width: 100%;
    height: 100%;
    padding: 5rem 0;

    .lastParagraph {
        margin-bottom: 5rem;
    }

    p {
        font-size: _variables.$size-paragraph-big;
        text-align: center;
    }

    .gold {
        color: _variables.$color-brown;
    }

    a {
        text-decoration: none;
        color: _variables.$color-brown;
    }
}

.technicalError {
    color: _variables.$color-brown;
}
