@use "@styles/_mixins" as _mixins;
@use "@styles/_variables" as _variables;

.tableHeader {
    @include _mixins.flex_container(row, space-around, center);
    background-color: _variables.$color-black;
    color: _variables.$color-white;
    padding: 0.5rem 0;
    font-size: _variables.$size-paragraph-very-small-trait;
    font-weight: normal;
}

thead {
    display: block;
}

th {
    font-weight: normal;
}

.name,
.types {
    flex: 2;
}

.select {
    flex: 1;
}

.types {
    option,
    select {
        width: 6.2rem;
        text-transform: uppercase;
        font-size: _variables.$size-paragraph-very-small-trait;
    }
}

.preview {
    width: 3.76rem;
}
