@use "@styles/_mixins" as _mixins;
@use "@styles/_variables" as _variables;

.pagerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem 0;
    border: none;

    span {
        font-size: _variables.$size-paragraph-small;
    }

    .nextBtn,
    .prevBtn {
        @include _mixins.flex_container(row, center, center);
        border: none;
        background-color: _variables.$color-transparent;
        cursor: pointer;
        width: 0.5rem;
        margin: 0 0.5rem;
        height: 1rem;

        img {
            width: 60%;
        }
    }

    .nextBtn {
        transform: rotate(180deg);
    }

    .prevBtn {
        margin-left: 0;
    }

    select {
        font-size: _variables.$size-paragraph-small;
    }
}
