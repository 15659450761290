@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-areas:
        "trait   avatar"
        "rareset remint"
        "freeze  freeze";
    grid-template-columns: 6rem 6rem;
    justify-content: end;
    gap: 1rem;

    .remint,
    .mintTraits,
    .downloadLink,
    .downloadLinkDisabled {
        grid-area: remint;
    }

    .freeze {
        grid-area: freeze;
    }

    .randomReset {
        grid-area: rareset;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .reset {
        grid-area: random;
    }

    .reset,
    .remint,
    .downloadLink,
    .freeze,
    .random {
        width: 6rem;
        height: 6rem;
        text-transform: uppercase;
        font-size: _variables.$size-paragraph-small;
        line-height: unset;
        outline: none;
    }

    .reset,
    .random {
        height: 2.7rem;
    }

    .freeze {
        height: 3.8rem;
        width: 100%;
    }

    .mintTraits {
        @extend .remint;
        background-color: _variables.$color-brown;
        border-color: _variables.$color-brown;
    }

    .remint:disabled {
        pointer-events: none;
        background-color: _variables.$color-medium-gray;
        border-color: _variables.$color-medium-gray;
        cursor: no-drop;
    }

    .disabled {
        pointer-events: none;
        background-color: _variables.$color-medium-gray;
        border-color: _variables.$color-medium-gray;
        cursor: no-drop;
    }

    .disabledFreeze {
        @extend .freeze;
        pointer-events: none;
        cursor: no-drop;
        background-color: _variables.$color-medium-gray;
        border-color: _variables.$color-medium-gray;
    }

    .downloadLink {
        @include _mixins.btn_primary(_variables.$color-solid-pink, _variables.$color-white);
    }

    .downloadLinkDisabled {
        @extend .downloadLink;
        pointer-events: none;
        cursor: no-drop;
        background-color: _variables.$color-medium-gray;
        border-color: _variables.$color-medium-gray;
    }

    .random {
        @include _mixins.btn_primary(_variables.$color-solid-pink, _variables.$color-white);
    }
}

@media screen and (max-width: #{_variables.$media-laptop-lm}) {
    .container {
        grid-template-columns: 5rem 5rem;

        .reset,
        .downloadLink,
        .remint,
        .random {
            width: 5rem;
            height: 5rem;
            font-size: _variables.$size-paragraph-very-small;
        }

        .freeze {
            height: 2.7rem;
            font-size: _variables.$size-paragraph-very-small;
        }

        .reset,
        .random {
            height: 2.2rem;
        }
    }
}
