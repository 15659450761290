@use "@styles/_variables" as _variables;

.loader {
    margin-top: 5rem;
}

.fullRow {
    width: 100%;
    display: block;
    border: none;

    td {
        height: 100%;
        border: none;
        width: 100%;
    }

    .notFound {
        width: 100%;
        text-align: center;
        margin-top: 2rem;
        font-size: _variables.$size-paragraph-small;
    }
}
