@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.btn {
    width: 6rem;
    height: 6rem;
}

.container {
    @include _mixins.flex_container(column, center, flex-start);
    width: _variables.$inner-content-max-width;
    background-color: _variables.$color-white;
    padding: 1.2rem 1.2rem 1rem 1.2rem;

    .identifier {
        @include _mixins.flex_container(row, center, flex-start);

        p {
            padding-bottom: 0.5rem;

            span {
                color: _variables.$color-brown;
            }
        }
    }

    .details {
        @include _mixins.flex_container(row, center, flex-start);
        width: 100%;

        .loader {
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;

            div {
                width: 18rem;
                height: 18rem;
                margin: 0;
                padding: 0;

                img {
                    min-width: 100%;
                }
            }
        }

        > div,
        .imageContainer img {
            width: 100%;
            height: 100%;
        }

        .imageContainer,
        .traitsContainer {
            flex: 1;
            margin-right: 1.3rem;
        }

        .buttons {
            flex: 0.8;
            align-self: end;
        }

        .trait {
            padding: 0.02rem 0 0.02rem 0;

            &:not(:last-child) {
                border-bottom: 0.1rem solid _variables.$color-light-gray;
            }

            p {
                @include _mixins.flex_container(row, space-between, center);
                width: 100%;
                text-transform: uppercase;

                > span {
                    font-size: _variables.$size-paragraph-small-trait;
                }
            }
        }

        .btnContainer {
            @include _mixins.flex_container(row, flex-end, flex-end);
            height: 100%;
            padding-bottom: 0.3rem;

            button,
            a {
                font-size: _variables.$size-button-small;
                line-height: 1.1rem;
            }

            .btnBlack {
                @extend .btn;
                background-color: _variables.$color-black;
                border: _variables.$color-black;
            }

            .btnPrimary {
                @extend .btn;
                margin-left: 0.5rem;
            }

            a {
                @include _mixins.btn_primary(_variables.$color-black, _variables.$color-white);
                @extend .btn;
            }
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        @include _mixins.flex_container(column, center, center);
        width: 100%;

        .identifier {
            @include _mixins.flex_container(column, center, flex-start);
        }

        .details {
            @include _mixins.flex_container(column, center, center);
            margin-bottom: 0.8rem;

            .imageContainer,
            .traitsContainer {
                margin-bottom: 2rem;
                margin-right: 0;
                width: 18rem;
            }

            .imageContainer img {
                width: 18rem;
                height: 18rem;
            }

            .trait {
                width: 100%;

                p span {
                    font-size: _variables.$mobile-paragraph-trait;
                }
            }

            .btnContainer {
                @include _mixins.flex_container(row, center, center);

                button {
                    line-height: 0.9rem;
                }
            }
        }
    }
}
