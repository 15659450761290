@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    @include _mixins.flex_container(column, center, center);
    width: 100%;
    height: auto;
    padding-bottom: 6.5rem;

    p {
        max-width: _variables.$inner-content-max-width;
        text-align: justify;
        margin-bottom: 3rem;
        font-weight: normal;
        color: _variables.$color-white;
    }

    .gold {
        color: _variables.$color-brown;
    }

    .connect {
        @include _mixins.flex_container(row, space-between, center);
        width: _variables.$inner-content-max-width;
        height: auto;
        margin-bottom: 2rem;

        img,
        .btnPrimary {
            width: 18rem;
            height: 18rem;
        }
    }

    .royalList {
        width: 18rem;
        background-color: _variables.$color-burning;
        border: none;
        color: _variables.$color-white;
        text-align: center;
        line-height: 2.5rem;
    }

    .disclaimer {
        font-size: _variables.$mobile-paragraph-small;
        margin-top: 2rem;
        text-align: center;
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        width: 100%;
        padding: 2rem;

        p {
            max-width: 100%;
            margin: 0 0 1.5rem 0;
            font-size: _variables.$mobile-paragraph-medium;
        }

        .gold {
            font-size: _variables.$mobile-paragraph-medium;
        }

        .connect {
            width: 100%;
            margin-bottom: 2rem;

            img,
            .btnPrimary {
                width: 28vw;
                height: 28vw;
            }

            .btnPrimary {
                font-size: _variables.$mobile-paragraph-small;
            }
        }

        .royalList {
            width: 28vw;
            font-size: _variables.$mobile-paragraph-small;
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-l) {
    .container {
        .connect {
            .btnPrimary {
                line-height: 0.8rem;
            }
        }

        .royalList {
            line-height: 0.8rem;
            padding: 1rem 0;
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-m) {
    .container {
        p,
        .gold {
            font-size: _variables.$mobile-paragraph-small;
            line-height: 1.5rem;
        }

        .connect {
            img,
            .btnPrimary {
                width: 26vw;
                height: 26vw;
            }
        }

        .royalList {
            width: 26vw;
            font-size: _variables.$mobile-paragraph-small;
        }
    }
}
