@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

$content-width: 32rem;

.container {
    padding: 0 3rem 5rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
}

.arrows {
    margin: auto;
    margin-top: 17.5rem;
}

.disabled {
    pointer-events: none;
    background-color: _variables.$color-medium-gray;
    border-color: _variables.$color-medium-gray;
    cursor: no-drop;
}

.loader {
    margin-top: 5rem;
    margin-bottom: _variables.$bottom-space-footer;
}

.simulatorTitle {
    max-width: calc(#{_variables.$inner-content-max-width} - #{_variables.$inner-content-correction});
    margin-bottom: 7rem;

    p,
    span {
        font-size: _variables.$size-paragraph-big;

        & span {
            color: _variables.$color-brown;
        }
    }

    &.noAvatars {
        text-align: center;
    }
}

.goToCollection {
    @include _mixins.btn_primary(_variables.$color-solid-pink, _variables.$color-white);
    text-transform: uppercase;
    margin-top: -4rem;
}

.grid {
    width: 100%;
    padding: 0 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5rem;

    .column {
        display: grid;
        justify-items: end;

        &:nth-child(2) {
            justify-items: start;
        }
    }

    .carouselBox {
        position: relative;
        width: calc($content-width + 7rem);
        min-height: 39rem;
        margin-bottom: 1rem;
        margin-right: -3.5rem;
        margin-top: -3.2rem;
    }

    .raritiesAndButtonsBox {
        width: $content-width;
        display: grid;
        column-gap: 1rem;
        grid-template-columns: 1.3fr 0.7fr;
        margin-bottom: 1rem;
    }
}

.avatarLoaderContainer {
    display: flex;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
}

.avatarLoader {
    height: auto;
    max-width: 10rem;
    animation: spin 1s linear infinite;
}

.carousel {
    p {
        margin-bottom: 1.35rem !important;
    }
}

.formContainer {
    position: absolute;
    z-index: 1;
    top: 2.9rem;
}

.searchForm {
    position: relative;
    width: 7rem;
    margin-left: 3.5rem;

    .searchInput {
        width: 100%;
        border: 0.1rem solid _variables.$color-black;
        outline: 0;
        appearance: none;
        padding: 0.1rem 2.5rem 0.1rem 0.2rem;
        -webkit-appearance: none;
        -moz-appearance: textfield;

        &:focus {
            outline: none;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    button {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        padding-right: 0.1rem;
        color: _variables.$color-white;
        font-size: _variables.$size-paragraph-small;
        background-color: _variables.$color-transparent;
        border: none;
        cursor: pointer;
        display: flex;

        > img {
            width: 80%;
        }

        &.cross {
            right: 1.1rem;
            padding-right: 0;

            img {
                width: 0.5rem;
                margin-bottom: 0.1rem;
            }
        }
    }
}

.assambledCongratz {
    text-align: center;

    span {
        color: _variables.$color-brown;
    }
}

@media screen and (max-width: _variables.$media-laptop-lm) {
    .container {
        grid-template-columns: 0.7fr 0.7fr;
        column-gap: 4rem;

        .carouselBox {
            width: 33rem;
            min-height: 33rem;
        }

        .raritiesAndButtonsBox {
            width: 26rem;
        }
    }
}
