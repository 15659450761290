@use "@styles/_mixins" as _mixins;
@use "@styles/_variables" as _variables;

.container {
    grid-area: preview;
    width: 20rem;
    margin-left: auto;
}

.placeholder {
    width: 20rem;
    height: 20rem;
    background-color: _variables.$color-gray-light;
}

.imageBox {
    width: 100%;

    > img {
        width: 20rem;
        height: 20rem;
        display: block;
        background-color: _variables.$color-gray-light;
    }

    .imgData {
        @include _mixins.flex_container(row, flex-end, center);

        .viewOp,
        .like {
            width: 3.5rem;
            height: 3.5rem;
            font-size: _variables.$size-button-very-small;
            line-height: 0.8rem;
        }

        .viewOp {
            @include _mixins.btn_primary(_variables.$color-brown, _variables.$color-white);
        }

        img {
            width: 100%;
        }

        .like {
            @include _mixins.flex_container(row, center, center);
            background-color: _variables.$color-transparent;
            border: none;
            padding: 0.7rem;
            cursor: pointer;
        }

        .nameId,
        & span {
            font-size: _variables.$size-paragraph-medium-trait;

            & {
                margin-right: auto;
            }

            span {
                color: _variables.$color-brown;
            }
        }
    }
}

.rank,
.voted,
.owner {
    font-size: _variables.$size-paragraph-very-small-trait;
    line-height: 1rem;
    margin-bottom: 1rem;

    span {
        font-size: _variables.$size-paragraph-very-small-trait;
        font-weight: bold;
        display: inline-block;
        margin-bottom: 0;
        line-height: inherit;
    }

    a {
        display: block;
        text-decoration: none;
        color: _variables.$color-brown;
        text-overflow: ellipsis;
        max-width: 20rem;
        white-space: nowrap;
        overflow: hidden;
    }
}

.voted {
    margin-bottom: 1rem;
}

.percent {
    font-size: _variables.$size-paragraph-small;
}

.owner {
    font-size: calc(#{_variables.$size-paragraph-very-small} - 0.02rem);
    font-weight: normal;
}

.rank {
    margin-bottom: 2.1rem;
}

.traits {
    padding: 0.02rem 0;

    &:not(:last-child) {
        border-bottom: 0.0625rem solid _variables.$color-light-gray;
    }

    p {
        @include _mixins.flex_container(row, flex-start, center);
        width: 100%;
        text-transform: uppercase;

        span {
            color: _variables.$color-black;
            font-size: _variables.$size-paragraph-very-small-trait;
        }

        .name {
            text-overflow: ellipsis;
            max-width: 11.25rem;
            white-space: nowrap;
            overflow: hidden;
        }

        .type {
            font-weight: bold;
        }

        .traitPercent {
            margin-left: auto;
        }
    }
}
