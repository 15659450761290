@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    @include _mixins.flex_container(column, center, center);
    width: 100%;
    padding-bottom: _variables.$bottom-space-footer;

    .details {
        padding: 5rem 0 3rem 0;
    }

    .title {
        color: _variables.$color-brown;
        margin-bottom: 0rem;
    }

    > p {
        width: _variables.$inner-content-max-width;
        text-align: justify;
        margin-bottom: 3rem;

        span {
            color: _variables.$color-brown;
        }
    }

    .partList {
        width: _variables.$inner-content-max-width;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        row-gap: 1rem;
        column-gap: 2.1rem;
        margin-bottom: 4.5rem;
    }

    .modifyTraitsButton {
        @include _mixins.btn_primary(_variables.$color-brown, _variables.$color-white);
        align-self: center;
        width: 7.5rem;
        height: 7.5rem;
        text-decoration: none;
        text-align: center;
        line-height: 1.1rem;
    }

    .createTraitsButton {
        @extend .modifyTraitsButton;
        width: 6rem;
        height: 6rem;
    }

    .confirmation {
        @include _mixins.flex_container(column, center, center);
        margin-bottom: 10rem;

        p {
            margin: 5rem 0;
            max-width: _variables.$inner-content-max-width;
            text-align: justify;

            span {
                color: _variables.$color-brown;
            }
        }

        img {
            margin-bottom: 5rem;
            max-width: 16rem;
        }
    }

    .downloadLink {
        @include _mixins.btn_primary(_variables.$color-solid-pink, _variables.$color-white);
        font-size: _variables.$size-paragraph-medium;
        line-height: 1.1rem;
    }

    .termsLink {
        text-decoration: none;
        color: _variables.$color-brown;
    }
}

@media screen and (max-width: _variables.$media-laptop-l) {
    .container {
        padding: 2rem;

        > p,
        span {
            font-size: _variables.$mobile-paragraph-medium;
        }

        .details {
            padding-top: 3rem;
        }

        .partList {
            column-gap: 5.2rem;

            .modifyTraitsButton {
                width: 5rem;
                height: 5rem;
            }
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        > p {
            width: 18rem;
        }

        .details {
            padding: 0 0 1.5rem 0;
        }

        .partList {
            width: 18rem;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 0rem;

            .modifyTraitsButton {
                margin-left: 0.65rem;
            }
        }

        .modifyTraitsButton {
            width: 5rem;
            height: 5rem;
        }

        .createTraitsButton {
            width: 6rem;
            height: 6rem;
            font-size: _variables.$size-paragraph-medium;
        }

        .downloadLink {
            width: 6rem;
            height: 6rem;
            font-size: _variables.$mobile-paragraph-medium;
            line-height: 0.9rem;
        }
    }
}
