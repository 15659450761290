@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    @include _mixins.flex_container(column, flex-start, center);
    margin-top: 1.4rem;
    width: 34rem;
    height: _variables.$collection-height;
    max-height: _variables.$collection-height;
}

.buttonTabs {
    @include _mixins.flex_container(row, center, center);
    min-width: 100%;
    width: 100%;

    .opensea,
    .myCollection {
        flex: 1 1 50%;
        padding: 1rem 0;
        border: none;
        color: _variables.$color-white;
        font-size: _variables.$size-paragraph-medium;
        text-transform: uppercase;
        letter-spacing: 0.05rem;
        cursor: pointer;
    }

    .opensea {
        background-color: _variables.$color-background-simulator;
    }

    .myCollection {
        background-color: _variables.$color-dark-gray;
    }
}

.content {
    @include _mixins.flex_container(row, center, center);
}

@media screen and (max-width: #{_variables.$media-laptop-lm}) {
    .container {
        width: 26rem;
        height: _variables.$collection-height;
        max-height: _variables.$collection-height;
    }

    .buttonTabs {
        .opensea,
        .myCollection {
            font-size: _variables.$size-paragraph-small;
        }
    }
}
