@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    width: 100%;
    @include _mixins.flex_container(column, space-between, center);

    .email {
        display: none;
    }

    > div {
        &:nth-child(1) {
            background: _variables.$color-brown;
            width: 100%;

            > div {
                width: _variables.$inner-content-max-width;
                height: 8rem;
                margin: auto;
                @include _mixins.flex_container(row, space-between, center);

                > div {
                    @include _mixins.flex_container(row, space-evenly, center);
                    width: 100%;

                    a {
                        img {
                            max-width: 12rem;
                            max-height: 5rem;
                        }
                    }
                }
            }
        }

        &:nth-child(2) {
            width: 100%;
            height: _variables.$height-footer;
            margin: auto;
            padding: 3rem 0;
            background: _variables.$color-black;
            @include _mixins.flex_container(column, space-between, center);

            .xlogo {
                width: 1.8rem;

                img {
                    width: 100%;
                }
            }

            > img {
                width: 13rem;
                margin-bottom: 3rem;
            }

            > div {
                margin: 0 auto 1rem auto;
                width: _variables.$inner-content-max-width;
                @include _mixins.flex_container(row, space-between, center);

                > div {
                    width: 13rem;
                    @include _mixins.flex_container(row, space-between, center);
                }
            }

            > p {
                width: _variables.$inner-content-max-width;
                font-size: _variables.$size-font-footer;
                color: _variables.$color-white;
                text-align: justify;
                letter-spacing: 0.08rem;

                > span {
                    font-size: _variables.$size-font-footer;
                    color: _variables.$color-brown;
                }
            }
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        width: 100%;

        a {
            text-decoration: none;
        }

        .email {
            display: block;
            font-size: 0.7rem;

            color: _variables.$color-white;
        }

        > div {
            &:nth-child(1) {
                width: 100%;

                > div {
                    width: 100%;
                }
            }

            &:nth-child(2) {
                width: 100%;
                padding: 2rem;
                height: _variables.$mobile-height-footer;

                > img {
                    width: 9rem;
                    margin-bottom: 2rem;
                }

                > div {
                    width: 100%;
                    @include _mixins.flex_container(column-reverse, center, center);

                    > div {
                        width: 22rem;
                        margin-bottom: 1.5rem;
                        align-self: start;
                    }
                }

                > p {
                    width: 100%;
                }

                p,
                p span {
                    font-size: 0.55rem;
                }
            }
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-l) {
    .container {
        > div {
            &:nth-child(1) {
                > div {
                    > div {
                        a {
                            img {
                                max-width: 25vw;
                                max-height: 15vw;
                            }
                        }
                    }
                }
            }

            &:nth-child(2) {
                padding: 2rem;

                .xlogo {
                    width: auto;
                }

                > p {
                    width: 100%;
                    line-height: 1.3rem;

                    > span {
                        line-height: 1.3rem;
                    }
                }

                > div {
                    > div {
                        > a {
                            img {
                                max-width: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-m) {
    .container {
        > div {
            &:nth-child(2) {
                height: _variables.$mobile-s-height-footer;

                > div {
                    > div {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-s) {
    .container {
        > div {
            &:nth-child(2) {
                > div {
                    > div {
                        > a {
                            img {
                                max-width: 0.8rem;
                            }
                        }
                    }
                }
            }
        }

        .email {
            font-size: 0.6rem;
        }
    }
}
