@use "@styles/_variables" as _variables;

.container {
    width: 100%;
    margin: auto;
    position: relative;
    padding-top: _variables.$height-header;

    > .content {
        min-height: _variables.$min-height-main;
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        padding-top: _variables.$height-header-mobile;
    }
}
