@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    width: 100%;
    max-width: 10rem;

    .preview {
        @include _mixins.flex_container(column, inherit, inherit);
    }

    p {
        color: _variables.$color-white;
        font-size: _variables.$size-paragraph-medium;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: #{_variables.$media-laptop-lm}) {
    .container {
        max-width: 6rem;

        p {
            font-size: _variables.$size-paragraph-small;
        }
    }
}
