@use "@styles/_mixins" as _mixins;
@use "@styles/_variables" as _variables;

.container {
    display: block;
    grid-area: table;
    width: 30rem;
    border-collapse: collapse;
}

thead,
tbody {
    display: block;
}

tbody {
    height: 37.6rem;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 0.2rem;
    }

    &::-webkit-scrollbar-track {
        background: _variables.$color-transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: _variables.$color-black;
    }
}

th {
    font-weight: normal;
}

.hidden {
    visibility: hidden;
}

.tableHeader {
    @include _mixins.flex_container(row, space-around, center);
    background-color: _variables.$color-black;
    color: _variables.$color-white;
    padding: 0.5rem 0;
    font-size: _variables.$size-paragraph-very-small-trait;
    font-weight: normal;
}

.avid,
.types {
    flex: 3;
}

.types {
    option,
    select {
        width: 6.2rem;
        text-transform: uppercase;
        font-size: _variables.$size-paragraph-very-small-trait;
    }
}

.preview {
    width: 3.76rem;
}

.rank,
.score,
.viewop {
    flex: 2;
}

.pagerContainer {
    padding: 0.5rem 0;
    border: none;

    span {
        font-size: _variables.$size-paragraph-small;
    }

    .nextBtn,
    .prevBtn {
        @include _mixins.flex_container(row, center, center);
        border: none;
        background-color: _variables.$color-transparent;
        cursor: pointer;
        width: 0.5rem;
        margin: 0 0.5rem;
        height: 1rem;

        img {
            width: 60%;
        }
    }

    .nextBtn {
        transform: rotate(180deg);
    }

    .prevBtn {
        margin-left: 0;
    }

    select {
        font-size: _variables.$size-paragraph-small;
    }
}
