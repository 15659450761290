@use "@styles/_variables" as _variables;

.container {
    padding-bottom: 10rem;

    p {
        text-align: center;
        color: _variables.$color-white;
    }
}

.title {
    text-align: center;
    color: _variables.$color-brown;
    font-weight: 400;
    margin-bottom: 2rem;
}

.loader {
    margin-top: 2rem;
    margin-bottom: 4rem;
}
