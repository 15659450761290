@use "@styles/_variables" as _variables;

.container {
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        color: _variables.$color-white;
    }

    span {
        color: _variables.$color-brown;
    }

    .royalList {
        width: 18rem;
        background-color: _variables.$color-burning;
        border: none;
        color: _variables.$color-white;
        text-align: center;
        line-height: 2.5rem;
        margin-bottom: 3rem;
    }
}

.last {
    margin-bottom: 3rem;
}

.check {
    max-width: 12rem;
    margin-bottom: 3rem;
}

.disclaimer {
    font-size: _variables.$size-span-small;
    font-weight: bold;
    margin-bottom: 3rem;
}
