@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    @include _mixins.flex_container(column, center, center);
    width: 100%;
    height: auto;
    padding-bottom: _variables.$bottom-space-footer;

    .onlyDesktop {
        @include _mixins.flex_container(row, center, center);
        width: 100%;
    }

    .onlyMobile {
        display: none;
    }

    .loader {
        margin-top: 0;
        padding-top: 0;

        div {
            padding-bottom: 0;
            margin-top: 0;
        }
    }

    p {
        max-width: calc(#{_variables.$inner-content-max-width} - #{_variables.$inner-content-correction});
        line-height: 1.8rem;
        text-align: justify;
        margin: 2rem 0;
        font-size: _variables.$size-paragraph-big;

        span {
            color: _variables.$color-brown;
        }

        &:nth-child(1) {
            font-size: 1.2rem;
            margin: 5rem 0 0 0;
        }
    }

    .traitListTitle {
        margin: 2rem 0 0 0;
        width: _variables.$inner-content-max-width;
        color: _variables.$color-brown;
    }

    .hackTheRoyals {
        @include _mixins.btn_primary(_variables.$color-solid-pink, _variables.$color-white);
        align-self: center;
        border: _variables.$color-solid-pink;
    }

    .goOpenSea {
        @include _mixins.btn_primary(_variables.$color-black, _variables.$color-white);
    }

    .hackTheRoyals,
    .goOpenSea {
        width: 6rem;
        height: 6rem;
        text-decoration: none;
        text-align: center;
        margin: 0 0 2rem 0;
        line-height: 1.1rem;
    }

    .discordLink {
        margin: auto;

        img {
            max-width: calc(#{_variables.$inner-content-max-width} - #{_variables.$inner-content-correction});
        }
    }

    .parts {
        @include _mixins.flex_container(column, center, center);
        padding-top: 1rem;
        width: 100%;

        .haveTraitsText {
            color: _variables.$color-black;
            font-size: _variables.$size-paragraph-big;
            width: _variables.$inner-content-max-width;
            margin: 1rem 0 3rem 0;
            padding: 0;
        }

        > div {
            @include _mixins.flex_container(row, center, flex-start);
            width: 100%;
            padding-right: 12.5rem;

            p {
                margin-right: 1rem;
                text-align: end;
                margin-top: 3rem;
                width: 12rem;
            }
        }
    }

    .noTraitsParagraph {
        margin: 0 0 2rem 0;
    }
}

@media screen and (max-width: _variables.$media-laptop-l) {
    .container {
        width: 100%;
        padding: 0 2rem 4.5rem 2rem;

        .discordLink {
            img {
                margin-bottom: 3rem;
            }
        }

        .traitListTitle {
            width: 100%;
        }

        .parts {
            width: _variables.$inner-content-max-width;

            > p {
                width: 100%;
                padding: 0 0.5rem;
                margin: 2rem;
                color: _variables.$color-brown;
            }

            > div {
                @include _mixins.flex_container(column, center, flex-start);
                padding-right: 0;

                p {
                    padding: 1.5rem;
                    padding-left: 0.5rem;
                    text-align: start;
                    margin-top: 0;
                    width: auto;
                }
            }
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        .goOpenSea {
            font-size: _variables.$mobile-paragraph-medium;
            line-height: 0.9rem;
        }

        .discordLink {
            img {
                max-width: 100%;
            }
        }

        p {
            margin-top: 1rem;

            &:nth-child(1) {
                margin: 2rem 0 0 0;
                font-size: _variables.$mobile-paragraph-big;
            }
        }

        .onlyDesktop {
            display: none;
        }

        .onlyMobile {
            display: block;
            max-width: 23rem;
        }

        p,
        span {
            font-size: _variables.$mobile-paragraph-medium;
        }

        .parts {
            width: 100%;

            .haveTraitsText {
                width: 100%;
                font-size: _variables.$mobile-paragraph-medium;
                margin: 0 0 1rem 0;
            }

            > p {
                padding: 0;
                margin-top: 2rem;
                margin-bottom: 1rem;
            }

            > div {
                @include _mixins.flex_container(column, center, flex-start);

                p {
                    padding: 1.5rem 0rem;
                    margin-top: 0;
                }
            }
        }
    }
}
