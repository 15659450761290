@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    @include _mixins.flex_container(row, center, center);

    div {
        width: 13rem;
        background-color: _variables.$color-gray-light;
        height: 2.5rem;
        @include _mixins.flex_container(row, center, center);
    }
}

@media screen and (max-width: _variables.$media-mobile-l) {
    .container {
        div {
            width: 17rem;
            height: 2rem;
        }
    }
}

@media screen and (max-width: _variables.$media-mobile-m) {
    .container {
        div {
            width: 15rem;
        }
    }
}
