@use "@styles/variables" as _variables;

$image-border-width: 0.5rem;

.arrow {
    width: 3rem;
    height: 2rem;
    border-radius: 2rem;
    background-image: url("~@images/carousel-chevron.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.avatarLoaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatarLoader {
    max-width: 12rem;
    animation: spin 1s linear infinite;
}

.container {
    width: calc(#{_variables.$inner-content-max-width} + #{_variables.$inner-content-correction} + 50px);

    div {
        height: 16.8rem;
        overflow-y: clip;
    }

    > div {
        border: $image-border-width solid _variables.$color-white;
        background-color: _variables.$color-white;
        text-align: center;

        a {
            height: 100%;
            display: inline-block;
            align-self: center;

            img {
                height: 100%;
            }
        }
    }

    .nextBtn {
        @extend .arrow;
        transform: rotate(180deg);
    }

    .prevBtn {
        @extend .arrow;
    }

    .imageContainer {
        > img {
            cursor: pointer;
        }
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        max-width: 100%;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
