@use "@styles/_variables" as _variables;

.container {
    width: 100%;
    min-height: _variables.$min-height-main;
    margin: auto;
    position: relative;
    padding-top: 7rem;
    background-color: _variables.$color-black;
}

.error {
    text-align: center;
}

.invert {
    background-color: _variables.$color-white;
}

.defualtLoader {
    min-height: _variables.$min-height-main;
    display: grid;
    place-items: center;

    .loader {
        margin-top: 2rem;
        margin-bottom: 10rem;
    }
}
