@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

.container {
    padding: 0 0.5rem;
    width: _variables.$inner-content-max-width;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    row-gap: 1rem;
    column-gap: 1rem;
    margin-bottom: 3rem;
}

@media screen and (max-width: _variables.$media-laptop-l) {
    .container {
        grid-template-columns: repeat(8, 1fr);
        row-gap: 1rem;
        column-gap: 1rem;
    }
}

@media screen and (max-width: _variables.$media-laptop-m) {
    .container {
        @include _mixins.flex_container(row, flex-start, center);
        width: 100%;
        margin-bottom: 0;
        overflow-x: auto;
        overflow-y: hidden;
        padding-bottom: 1rem;
    }
}
