@use "@styles/_variables" as _variables;
@use "@styles/_mixins" as _mixins;

@font-face {
    font-family: "Montserrat";
    src: local("Montserrat-Medium"), url("assets/fonts/Montserrat-Medium.ttf") format("truetype");
    font-weight: medium;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: local("Montserrat-Bold"), url("assets/fonts/Montserrat-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Bebas-Neue";
    src: local("BebasNeue-Regular"), url("assets/fonts/BebasNeue-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: _variables.$font-montserrat-medium;
}

::root {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.maintenance {
    align-items: center;
    background: _variables.$color-solid-pink;
    color: _variables.$color-brown;
    text-transform: uppercase;
    width: 100%;
    font-size: 3rem;
    font-style: normal;
    font-weight: bold;
    text-align: center;
}

.ReactModal__Body--open {
    height: 100vh;
    overflow-y: hidden;
    padding-right: 15px;
}

#root {
    position: relative;
    z-index: 0;
}

textarea:focus,
input:focus,
select {
    outline: none;
}

body,
html {
    width: 100%;
    height: 100%;
}

h1,
h2 {
    font-size: _variables.$size-title-medium;
    font-style: normal;
    font-weight: bold;
    line-height: 2.188rem;
}

h3 {
    font-family: _variables.$font-montserrat-medium;
    line-height: 2.188rem;
    font-style: normal;
    font-weight: bold;
    font-size: 1.1rem;
}

h4 {
    font-family: _variables.$font-montserrat-medium;
    line-height: 2.188rem;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
}

h5 {
    font-family: _variables.$font-montserrat-medium;
    line-height: 2.188rem;
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
}

span {
    font-family: _variables.$font-montserrat-medium;
    font-style: normal;
    font-weight: 500;
    font-size: _variables.$size-span-medium;
    line-height: 1.7rem;
}

a {
    font-family: _variables.$font-montserrat-medium;
    font-weight: normal;
    font-style: normal;
}

p {
    font-size: _variables.$size-paragraph-big;
    letter-spacing: 0.1rem;
    line-height: 1.7rem;
}

.notAvailableInMobile {
    @include _mixins.flex_container(row, center, center);
    min-height: _variables.$min-height-main;
    height: 100%;
    background-color: _variables.$color-solid-pink;

    p {
        width: 100%;
        text-align: center;
        color: _variables.$color-brown;
    }
}
